import React, { useEffect } from 'react'
import Cookies from 'js-cookie'
import axios from 'axios'
import ReactGA from 'react-ga4'
import { Routes, Route } from 'react-router-dom'
import { MsalProvider, useMsal } from '@azure/msal-react'
import { EventType } from '@azure/msal-browser'
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { b2cPolicies } from './Config/authConfig'
import { compareIssuingPolicy } from './Utils/claimUtil'

import Home from './Pages/Home/Home'
import Postlogin from './Pages/Home/PostLogin'
import Login from './Pages/Home/Login'
import Thanks from './Pages/Home/ThankYou'
import Terms from './Pages/Terms/Terms'
import IdVerify from './Pages/IdVerify/IdVerify'
import CompanyInfo from './Pages/Company/CompanyInfo'
import Ubo from './Pages/ShareHolder/ShareHolder'

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID
ReactGA.initialize(TRACKING_ID)
var clickPluginInstance = new ClickAnalyticsPlugin();
var clickPluginConfig = {
    autoCapture: true
};
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_AZURE_APP_INSIGHTS,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin, clickPluginInstance],
        extensionConfig: {
            [clickPluginInstance.identifier]: clickPluginConfig
        }
    }
});
appInsights.loadAppInsights();
const Pages = () => {
    const { instance } = useMsal()
    useEffect(() => {
        const callbackId = instance.addEventCallback((event) => {
            if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && event.payload.account) {
                const { payload } = event
                Cookies.set("token", payload.idToken, { expires: 7 })
                if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
                    const originalSignInAccount = instance.getAllAccounts().find(
                        (account) =>
                            account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                            account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                            account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn
                    )
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount,
                    }
                    instance.ssoSilent(signUpSignInFlowRequest)
                }
                if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                    }
                    instance.loginRedirect(signUpSignInFlowRequest)
                }
            }
            if (event.eventType === EventType.LOGIN_FAILURE) {
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes: [],
                    }
                    instance.loginRedirect(resetPasswordRequest)
                }
            }
        })
        axios.get('https://ipapi.co/json/').then((response) => {
            Cookies.set("REGION", response.data.country, { expires: 1 })
        }).catch((error) => {
            Cookies.set("REGION", "MT", { expires: 1 })
        })

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId)
            }
        }
    }, [instance])

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/postlogin" element={<Postlogin />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/terms/:id" element={<Terms />} />
            <Route path="/login" element={<Login />} />
            <Route path="/info" element={<CompanyInfo />} />
            <Route path="/shareholder" element={<Ubo />} />
            <Route path="/idverify" element={<IdVerify />} />
            <Route path="/idverify/:id" element={<IdVerify />} />
            <Route path="/thankyou" element={<Thanks />} />
        </Routes>
    )
}

const App = ({ instance }) => {
    useEffect(() => {
        // CookieConsent.run({
        //     disablePageInteraction: true,
        //     mode:"opt-in",
        //     cookie: {
        //         name: 'cc_cookie',
        //         domain: 'signup.paymix.eu',
        //         path: '/',
        //         expiresAfterDays: 365,
        //     },
        //     guiOptions: {
        //         consentModal: {
        //             layout: 'cloud inline',
        //             position: 'bottom center',
        //             equalWeightButtons: true,
        //             flipButtons: false
        //         },
        //         preferencesModal: {
        //             layout: 'box',
        //             equalWeightButtons: true,
        //             flipButtons: false
        //         }
        //     },
        //     onFirstConsent: ({cookie}) => {
        //         console.log('onFirstConsent fired',cookie);
        //     },
        //     onConsent: ({cookie}) => {
        //         console.log('onConsent fired!', cookie)
        //     },
        //     onChange: ({changedCategories, changedServices}) => {
        //         console.log('onChange fired!', changedCategories, changedServices);
        //     },
        //     onModalReady: ({modalName}) => {
        //         console.log('ready:', modalName);
        //     },
        //     onModalShow: ({modalName}) => {
        //         console.log('visible:', modalName);
        //     },
        //     onModalHide: ({modalName}) => {
        //         console.log('hidden:', modalName);
        //     },
        //     categories: {
        //         necessary: {
        //             enabled: true,
        //             readOnly: true
        //         },
        //         analytics: {
        //             readOnly: false,
        //             autoClear: {
        //                 cookies: [
        //                     {
        //                         name: /^_ga/,   // regex: match all cookies starting with '_ga'
        //                     },
        //                     {
        //                         name: '_gid',   // string: exact cookie name
        //                     },
        //                     {
        //                         name: '_fbp',   // string: exact cookie name
        //                     },
        //                     {
        //                         name: '_gcl_au',   // string: exact cookie name
        //                     }
        //                 ]
        //             },
        //         }
        //     },
        //     language: {
        //         default: 'en',
        //         translations: {
        //             en: {
        //                 consentModal: {
        //                     title: 'We value your privacy',
        //                     description: 'We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.',
        //                     acceptAllBtn: 'Accept all',
        //                     acceptNecessaryBtn: 'Reject all',
        //                     showPreferencesBtn: 'Customize',
        //                 },
        //                 preferencesModal: {
        //                     title: 'Customize Consent Preferences',
        //                     acceptAllBtn: 'Accept all',
        //                     acceptNecessaryBtn: 'Reject all',
        //                     savePreferencesBtn: 'Accept current selection',
        //                     closeIconLabel: 'Close modal',
        //                     serviceCounterLabel: 'Service|Services',
        //                     sections: [
        //                         {
        //                             title: '',
        //                             description: `We use cookies to help you navigate efficiently and perform certain functions. You will find detailed information about all cookies under each consent category below.
        //                             The cookies that are categorized as "Necessary" are stored on your browser as they are essential for enabling the basic functionalities of the site.
        //                             We also use third-party cookies that help us analyze how you use this website, store your preferences, and provide the content and advertisements that are relevant to you. These cookies will only be stored in your browser with your prior consent.
        //                             You can choose to enable or disable some or all of these cookies but disabling some of them may affect your browsing experience.`,
        //                         },
        //                         {
        //                             title: 'Necessary',
        //                             description: 'Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.',
        //                             //this field will generate a toggle linked to the 'necessary' category
        //                             linkedCategory: 'necessary',
        //                             cookieTable: {
        //                                 caption: 'Cookie table',
        //                                 headers: {
        //                                     name: 'Cookie',
        //                                     domain: 'Duration',
        //                                     desc: 'Description'
        //                                 },
        //                                 body: [
        //                                     {
        //                                         name: 'ai_user',
        //                                         domain: '1 year',
        //                                         desc: 'Microsoft Azure sets this cookie as a unique user identifier cookie, enabling counting of the number of users accessing the application over time.',
        //                                     },{
        //                                         name: 'ai_session',
        //                                         domain: '1 hour',
        //                                         desc: 'This is a unique anonymous session identifier cookie set by Microsoft Application Insights software to gather statistical usage and telemetry data for apps built on the Azure cloud platform.',
        //                                     },{
        //                                         name: '__cfruid',
        //                                         domain: 'session',
        //                                         desc: 'Cloudflare sets this cookie to identify trusted web traffic.',
        //                                     },{
        //                                         name: 'rc::a',
        //                                         domain: 'never',
        //                                         desc: 'This cookie is set by the Google recaptcha service to identify bots to protect the website against malicious spam attacks.',
        //                                     },{
        //                                         name: 'rc::c',
        //                                         domain: 'session',
        //                                         desc: 'This cookie is set by the Google recaptcha service to identify bots to protect the website against malicious spam attacks.',
        //                                     },{
        //                                         name: 'LANG',
        //                                         domain: '7 days',
        //                                         desc: 'This cookie to used set user\'s preferred language.',
        //                                     },{
        //                                         name: 'REGION',
        //                                         domain: '7 days',
        //                                         desc: 'This cookie to used set user\'s region so specific questions can be shown/hidden.',
        //                                     },{
        //                                         name: 'ENTITY',
        //                                         domain: '7 days',
        //                                         desc: 'This cookie to used set user\'s Unique ID in our system.',
        //                                     },{
        //                                         name: 'COMPANYINFO',
        //                                         domain: '7 days',
        //                                         desc: 'This cookie to used save user\'s answers to enable easier completion of the application.',
        //                                     },{
        //                                         name: 'TOKEN',
        //                                         domain: 'session',
        //                                         desc: 'Microsoft Azure sets this cookie as a unique user signup identifier cookie',
        //                                     },{
        //                                         name: 'IDINFO',
        //                                         domain: 'session',
        //                                         desc: 'This cookie to used save user\'s answers to enable easier completion of the application.',
        //                                     },
                                            
        //                                 ]
        //                             }
        //                         },
        //                         {
        //                             title: 'Analytics',
        //                             description: 'Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.',
        //                             linkedCategory: 'analytics',
        //                             cookieTable: {
        //                                 caption: 'Cookie table',
        //                                 headers: {
        //                                     name: 'Cookie',
        //                                     domain: 'Duration',
        //                                     desc: 'Description'
        //                                 },
        //                                 body: [
        //                                     {
        //                                         name: '_fbp',
        //                                         domain: '3 Months',
        //                                         desc: 'Facebook sets this cookie to display advertisements when either on Facebook or on a digital platform powered by Facebook advertising after visiting the website.',
        //                                     },
        //                                     {
        //                                         name: '_hjTLDTest',
        //                                         domain: 'session',
        //                                         desc: 'To determine the most generic cookie path that has to be used instead of the page hostname, Hotjar sets the _hjTLDTest cookie to store different URL substring alternatives until it fails.',
        //                                     },
        //                                     {
        //                                         name: '_ga_*',
        //                                         domain: '1 year 1 month 4 days',
        //                                         desc: 'Google Analytics sets this cookie to store and count page views.',
        //                                     },
        //                                     {
        //                                         name: '_gat_UA-*',
        //                                         domain: '1 minute',
        //                                         desc: 'Google Analytics sets this cookie for user behaviour tracking.',
        //                                     },
        //                                     {
        //                                         name: '_gid',
        //                                         domain: '1 day',
        //                                         desc: 'Google Analytics sets this cookie to store information on how visitors use a website while also creating an analytics report of the website\'s performance. Some of the collected data includes the number of visitors, their source, and the pages they visit anonymously.',
        //                                     },
        //                                     {
        //                                         name: '_ga',
        //                                         domain: '1 year 1 month 4 days',
        //                                         desc: 'Google Analytics sets this cookie to calculate visitor, session and campaign data and track site usage for the site\'s analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognise unique visitors.',
        //                                     },
        //                                     {
        //                                         name: '_gcl_au',
        //                                         domain: '3 months',
        //                                         desc: 'Google Tag Manager sets the cookie to experiment advertisement efficiency of websites using their services.',
        //                                     },
        //                                     {
        //                                         name: '_hjSession_*',
        //                                         domain: '1 hour',
        //                                         desc: 'Hotjar sets this cookie to ensure data from subsequent visits to the same site is attributed to the same user ID, which persists in the Hotjar User ID, which is unique to that site.',
        //                                     },
        //                                     {
        //                                         name: '_hjSessionUser_*',
        //                                         domain: '1 year',
        //                                         desc: 'Hotjar sets this cookie to ensure data from subsequent visits to the same site is attributed to the same user ID, which persists in the Hotjar User ID, which is unique to that site.',
        //                                     }
        //                                 ]
        //                             }
        //                         },
        //                     ]
        //                 }
        //             }
        //         }
        //     }
        // });
    }, [])

    return (
        <AppInsightsErrorBoundary onError={() => <h1>Something went wrong..</h1>} appInsights={reactPlugin}>
            <MsalProvider instance={instance}>
                <Pages />
            </MsalProvider>
        </AppInsightsErrorBoundary>
    )
}
export default withAITracking(reactPlugin, App);