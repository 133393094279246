import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import ReactGA from "react-ga4";
import {setGAEvent} from '../../Utils/googleAnalytics'
import Layout from '../../Containers/Layout'
import img from '../../Assets/account_thanks.png'

const ThankYou = () => {
    const { t } = useTranslation()
    useEffect(() => {
        setGAEvent("page_view","Thank You Page","Thank You")
        Cookies.remove('token')
        Cookies.remove('userId')
        Cookies.remove('COMPANYINFO')
        Cookies.remove('ENTITY')
        Cookies.remove('IDINFO')
    })
    return (
        <Layout>
            <div className="d-flex flex-column justify-content-center align-items-center fullHeight">
                <div className="W550 pt-4 text-center">
                    <h3 className="text-center">{t("thank_you_title")}</h3><br />
                    <p className='text-left'>{t("thank_you_info")}</p><br />
                </div>
                <div className="text-center">
                    <img src={img} alt="Paymix SoftPos" style={{ 'width': "300px" }} />
                </div>
            </div>
        </Layout>
    )
}

export default ThankYou