import React, { Component } from 'react'
import Cookies from 'js-cookie'
import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import Select, { countryData } from '../../Components/UI/Select/Select'
import { URL_UBO } from '../../Config/urlList'
import {setGAEvent} from '../../Utils/googleAnalytics'
import personaAxios from '../../Utils/axiosPersona'
import ApiError from '../../Components/UI/Alert/ApiError'
import Layout from '../../Containers/Layout'
import Button from '../../Components/UI/Button/Button'
import Loader from '../../Components/UI/Loader/Loader'

class ShareHolder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showErrModal: false,
            showModalErr: "",
            redirect: false,
            gotoHome: false,
            showLoader: true,
            saveBtn: true,
            token: '',
            entityId: '',
            firstName: "",
            lastName: "",
            nationality: Cookies.get("REGION"),
            residency: Cookies.get("REGION"),
            share: 0,
            uboList: [],
            isEdit: -1,
            percentage: 0
        }
    }
    componentDidMount() {
        if (Cookies.get("token") === undefined) {
            this.setState({ redirect: true, gotoHome: true })
        } else {
            setGAEvent("page_view","Share Holder","Share Holder")
            const entityId = Cookies.get("ENTITY")
            if (entityId === 12) {
                this.setState({ showLoader: false, redirect: true })
            } else {
                this.setState({ showLoader: false })
            }
        }
    }
    deleteUbo = (id) => {
        const newArray = [...this.state.uboList]
        newArray.splice(id, 1)
        setGAEvent("page_view","Share Holder Delete Btn","Share Holder")
        this.setState({ uboList: newArray })
    }
    validateForm = () => {
        const { t } = this.props
        let share = parseInt(this.state.percentage)
        if (this.state.isEdit !== -1) {
            share -= this.state.uboList[this.state.isEdit].share
        }
        if (this.state.firstName.length === 0) {
            this.setState({ showErrModal: true, showModalErr: t('err_ubo_first') })
        } else if (this.state.lastName.length === 0) {
            this.setState({ showErrModal: true, showModalErr: t('err_ubo_last') })
        } else if (this.state.nationality.length !== 2) {
            this.setState({ showErrModal: true, showModalErr: t('err_ubo_nationality') })
        } else if (this.state.residency.length !== 2) {
            this.setState({ showErrModal: true, showModalErr: t('err_ubo_residency') })
        } else if (this.state.share <= 0) {
            this.setState({ showErrModal: true, showModalErr: t('err_ubo_share') })
        } else if (this.state.share > 100) {
            this.setState({ showErrModal: true, showModalErr: t('err_ubo_share1') })
        } else if ((share + parseInt(this.state.share)) > 100) {
            this.setState({ showErrModal: true, showModalErr: t('err_ubo_share2') })
        } else {
            setGAEvent("page_view","Share Holder Add Btn","Share Holder")
            const newArray = [...this.state.uboList]
            if (this.state.isEdit !== -1) {
                share -= this.state.uboList[this.state.isEdit].share
                newArray.splice(this.state.isEdit, 1)
            } else {
                share = parseInt(this.state.percentage)
            }
            share += parseInt(this.state.share)
            newArray.push({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                residency: this.state.residency,
                nationality: this.state.nationality,
                share: parseInt(this.state.share)
            })
            this.setState({
                firstName: "",
                lastName: "",
                nationality: Cookies.get("REGION"),
                residency:Cookies.get("REGION"),
                share: 0,
                uboList: newArray,
                saveBtn: false,
                percentage: share,
                isEdit: -1
            })
        }
    }

    sendToServer = () => {
        setGAEvent("page_view","Share Holder Next Btn","Share Holder")
        this.setState({ showLoader: true })
        const entityId = Cookies.get("ENTITY")
        const requestUBO = []
        this.state.uboList.forEach(ele => {
            let temp = {
                "uboFirstName": ele.firstName,
                "uboSurname": ele.lastName,
                "ubosHare": ele.share,
                "uboResidency": ele.residency,
                "uboNationality": ele.nationality,
            }
            requestUBO.push(temp)
        })
        const requestBody = {
            "entityID": entityId,
            "ubos": requestUBO,
        }
        const config = {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
                'Accept-Language':Cookies.get("LANG")
            }
        }
        personaAxios.post(URL_UBO, requestBody, config).then(res => {
            Cookies.set("ENTITY", 12, { expires: 7 })
            this.setState({ showLoader: false, redirect: true })
        }).catch(err => {
            if (err.code === 'ECONNABORTED') {
                this.setState({ showErrModal: true, showModalErr: "We have encountered an error. Kindly inform support with error code: 15 ", showLoader: false })
            } else {
                if (err.response && err.response.data && err.response.data.invalidFields && err.response.data.invalidFields.length > 0) {
                    this.setState({ showErrModal: true, showModalErr: err.response.data.invalidFields[0], showLoader: false })
                } else {
                    this.setState({ showErrModal: true, showModalErr: "An error occured ", showLoader: false })
                }
            }
        })
    }
    setUbo = (id) => {
        setGAEvent("page_view","Share Holder Edit Btn","Share Holder")
        const uboInfo = this.state.uboList[id]
        this.setState({
            isEdit: id,
            firstName: uboInfo.firstName,
            lastName: uboInfo.lastName,
            nationality: uboInfo.nationality,
            residency: uboInfo.residency,
            share: parseInt(uboInfo.share)
        })
    }
    doDelete = (id) => {
        const { t } = this.props
        if (window.confirm(t('ubo_confirm'))) {
            let share = this.state.percentage
            share -= this.state.uboList[id].share
            this.deleteUbo(id)
            this.setState({ percentage: share, showErrModal: true, showModalErr: t('ubo_delete') })
        }
    }
    render() {
        if (this.state.redirect) {
            if (this.state.gotoHome) {
                return (<Navigate to='/' replace={true} />)
            } else {
                return (<Navigate to='/idverify' replace={true} />)
            }
        }
        const { t } = this.props
        if (this.state.showLoader) {
            return (
                <Layout>
                    <div className="container d-flex flex-column justify-content-center fullHeight pt-4">
                        <Loader />
                    </div>
                </Layout>
            )
        }
        return (
            <>
                <ApiError isSuccess={false} show={this.state.showErrModal} modalClosed={() => this.setState({ showErrModal: false })} modalType="Alert1">
                    <div className="p-3">
                        {this.state.showModalErr}
                    </div>
                </ApiError>
                <Layout>
                    <div className="container d-flex flex-column justify-content-start align-items-center fullHeight pt-4">
                        <h2>{t('step_2_heading')}</h2><br />
                        <div className="container d-flex flex-row justify-content-between mobiRow">
                            <div className='d-flex flex-column col-md-6 ms-4 mobiColumn '>
                                <div className="">
                                    <label className="form-label">{t('lbl_ubo_first')}</label>
                                    <input className="form-control W550" type="text" value={this.state.firstName} onChange={(e) => this.setState({ firstName: e.target.value })} />
                                </div>
                                <div className="mt-3">
                                    <label className="form-label">{t('lbl_ubo_last')}</label>
                                    <input className="form-control W550" type="text" value={this.state.lastName} onChange={(e) => this.setState({ lastName: e.target.value })} />
                                </div>
                                <div className="mt-3">
                                    <label className="form-label">{t('lbl_ubo_nationality')} <sup>*</sup></label>
                                    <Select
                                        styles="form-control W550"
                                        clicked={(value) => { this.setState({ nationality: value }) }}
                                        selectedItem={this.state.nationality}
                                        options={countryData()}
                                    />
                                </div>
                                <div className="mt-3">
                                    <label className="form-label">{t('lbl_ubo_residency')} <sup>*</sup></label>
                                    <Select
                                        styles="form-control W550"
                                        clicked={(value) => { this.setState({ residency: value }) }}
                                        selectedItem={this.state.residency}
                                        options={countryData()}
                                    />
                                </div>
                                <div className="mt-3">
                                    <label className="form-label">{t('lbl_ubo_share')}</label>
                                    <input className="form-control W550" type="number" maxLength={3} value={this.state.share} onChange={(e) => this.setState({ share: e.target.value.slice(0, 3) })} />
                                </div>
                            </div>
                            <div className='d-flex flex-column col-md-6 mobiColumn mobiTop'>
                                <table className="table table-bordered table-hover mt-2" cellPadding={10}>
                                    <thead>
                                        <tr>
                                            <th>{t('tbl_ubo_name')}</th>
                                            <th>{t('tbl_ubo_Share')}</th>
                                            <th>{t('tbl_ubo_actions')}</th>
                                        </tr>
                                    </thead><tbody>
                                        {(this.state.uboList.length === 0) ? (
                                            <tr>
                                                <td colSpan={3} className='text-center p-5'>
                                                    {t('err_no_ubo')}
                                                </td>
                                            </tr>
                                        ) : null}
                                        {this.state.uboList.map((el, index) => (
                                            <tr key={index}>
                                                <td>{el.firstName} {el.lastName}</td>
                                                <td>{el.share}%</td>
                                                <td>
                                                    <span className='pointer fontBold' onClick={() => this.setUbo(index)}>{t('btn_edit')}</span>&nbsp;&nbsp;
                                                    <span className='pointer fontBold redText' onClick={() => this.doDelete(index)}>{t('btn_del')}</span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div className="container d-flex flex-row justify-content-center hideMobi mb-5">
                            <div className='d-flex me-5'>
                                <Button clicked={this.validateForm} btnType={"mt-5 ButtonLogin W250"} type="submit">{t('btn_add')}</Button>
                            </div>
                            <div className='d-flex ms-5'>
                                <Button clicked={this.sendToServer} btnType={"mt-5 ButtonLogin W250"} type="submit" disabled={this.state.saveBtn}>{t('btn_next')}</Button>
                            </div>
                        </div>
                        <div className="container d-flex flex-column justify-content-center align-items-center showMobi mb-5">
                            <Button clicked={this.validateForm} btnType={"mt-5 ButtonLogin W250"} type="submit">{t('btn_add')}</Button>
                            <Button clicked={this.sendToServer} btnType={"mt-4 ButtonLogin W250"} type="submit" disabled={this.state.saveBtn}>{t('btn_next')}</Button>
                        </div>
                    </div>
                </Layout>
            </>
        )
    }
}
export default withTranslation()(ShareHolder)