import React, { useEffect, useState, useRef } from 'react'
import Cookies from 'js-cookie'
import ReCAPTCHA from 'react-google-recaptcha'
import ReactGA from "react-ga4";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { useTranslation } from 'react-i18next'
import { b2cPolicies } from '../../Config/authConfig'
import {setGAEvent} from '../../Utils/googleAnalytics'
import Layout from '../../Containers/Layout'
import Button from '../../Components/UI/Button/Button'
import Loader from '../../Components/UI/Loader/Loader'
import ApiError from '../../Components/UI/Alert/ApiError'

const Terms = () => {
    const recaptcha = useRef()
    const { instance } = useMsal()
    const { t } = useTranslation()
    const [tUrl, setTUrl] = useState('')
    const [errModal, setErrModal] = useState(false)
    const [loader, setLoader] = useState(false)
    let lang = Cookies.get("LANG");
    const request1 = {
        authority: b2cPolicies.authorities.register.authority,
        scopes: [],
        prompt: 'login'
    }
    useEffect(() => {
        setGAEvent("page_view","Terms Page","Terms Page")
        const getIdApplication = window.location.href.substring(
            window.location.href.lastIndexOf("/") + 1
        )
        if (getIdApplication !== "goNext" && getIdApplication.length > 0) {
        }
        let country = Cookies.get("REGION")
        let lang = Cookies.get("LANG")
        if (country === 'DE' && lang === 'de') {
            setTUrl("https://www.paymix.eu/terms_DE_de.html")
        } else if (country === 'DE') {
            setTUrl("https://www.paymix.eu/terms_DE_en.html")
        } else {
            setTUrl("https://www.paymix.eu/terms_MT.html")
        }
    }, [tUrl])
    const doLogout = async () => {
        try {
            Cookies.remove("isSignup")
            await instance.logoutRedirect()
        } catch (err) {
        }
    }
    const checkCaptcah = async () => {
        const captchaValue = recaptcha.current.getValue()
        if (!captchaValue) {
            setErrModal(true)
        } else {
            doLogin()
        }
    }
    const doLogin = async () => {
        setGAEvent("page_view","Terms Page Login Click","Terms Page")
        try {
            await instance.loginRedirect(request1)
            Cookies.set("isSignup", true, { expires: 1 })
        } catch (err) {
        }
    }
    return (
        <>
            <ApiError isSuccess={false} show={errModal} modalClosed={() => setErrModal(false)} modalType="Alert1">
                <div className="p-3">
                    {t("err_recaptcha")}
                </div>
            </ApiError>
            <Layout>
                {loader ? (
                    <div className="container d-flex flex-column justify-content-start align-items-center fullHeight pt-4">
                        <Loader />
                    </div>
                ) : (
                    <div className="container d-flex flex-column justify-content-start align-items-center fullHeight pt-4">
                        <h2 className='ms-2'>{t("step_1_t")}</h2><br />
                        <h4 className='mobiLeft'>{t("step_1_i")}</h4><hr />
                        <UnauthenticatedTemplate>
                            <iframe src={tUrl} title="our terms" width="95%" height="500" />
                            <hr />
                            <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                            <Button clicked={() => checkCaptcah()} btnType={"mt-3 mb-5 W400"} type="submit">{t("btn_accept")}</Button>
                        </UnauthenticatedTemplate>
                        <AuthenticatedTemplate>
                            <div className="W550 text-center">
                                <p className="mt-5 text-start">{t("account_info")}</p>
                                <Button clicked={() => doLogout()} btnType={"mt-4 mb-3 ButtonLogin"} type="submit">{t("btn_next")}</Button>
                            </div>
                        </AuthenticatedTemplate>
                    </div>
                )}
            </Layout>
        </>
    )
}

export default Terms