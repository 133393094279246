import React, { Component } from 'react'

import Backdrop from '../Backdrop/Backdrop'
import './Modal.css'

class Modal extends Component {
    render() {
        return (
            <React.Fragment>
                <Backdrop show={this.props.show} clicked={this.props.modalClosed} />
                <div
                    style={{
                        transform: this.props.show ? "translateY(0)" : "translateY(-100vh)",
                        opacity: this.props.show ? "1" : "0",
                        display: this.props.show ? "block" : "hidden"
                    }}
                    className={["Modal ", [this.props.modalType]].join(" ")}
                >
                    {this.props.children}
                </div>
            </React.Fragment>
        )
    }
}

export default Modal