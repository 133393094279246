export const isNum = (val) => {
    const intVal = val.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
    return intVal
}

export const toTitlecase = (str) => {
    return str.toLowerCase().replace(/\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() +
                txt.substr(1).toLowerCase()
        })
}

export const nameToChar = (str) => {
    const temp = str.match(/\b(\w)/g)
    return temp.join("").slice(0, 2).toUpperCase()
}

export const findObjectByCategory = (array, category) => {
    const result = array.find(obj => obj.category === category)
    if (result) {
        return result.content
      } else {
        return null
      }
}
export const formatDate = (originalDateString) => {
    const originalDate = new Date(originalDateString)
    const month = originalDate.getMonth() + 1
    const day = originalDate.getDate()
    const year = originalDate.getFullYear()
    const formattedMonth = month < 10 ? `0${month}` : `${month}`
    const formattedDay = day < 10 ? `0${day}` : `${day}`
    const formattedDateString = `${formattedMonth}/${formattedDay}/${year}`

    return formattedDateString
}
export const getCompanyType = (t) => ([
    { id: 0, val: t('select_default') },
    { id: 1, val: t('company_type_1') },
    { id: 2, val: t('company_type_2') },
    { id: 3, val: t('company_type_3') },
    { id: 4, val: t('company_type_4') }
])
export const getIndustry = (t) => (
    [
        { id: 0, val: t('select_default') },
        { val: t('industry_type_1'), id: 'Accommodation' },
        { val: t('industry_type_2'), id: 'Financial services' },
        { val: t('industry_type_3'), id: 'Activities of head offices' },
        { val: t('industry_type_4'), id: 'Architectural and engineering activities' },
        { val: t('industry_type_5'), id: 'Computer programming, consultancy and related activities' },
        { val: t('industry_type_6'), id: 'Creative arts and entertaining activities' },
        { val: t('industry_type_7'), id: 'Education' },
        { val: t('industry_type_8'), id: 'Employment activities' },
        { val: t('industry_type_9'), id: 'Food and beverage service activities' },
        { val: t('industry_type_10'), id: 'Human health activities' },
        { val: t('industry_type_11'), id: 'Insurance, reinsurance and pension funding' },
        { val: t('industry_type_12'), id: 'Land transport' },
        { val: t('industry_type_13'), id: 'Legal and accounting activities' },
        { val: t('industry_type_14'), id: 'Libraries, archives, museums and other cultural activities' },
        { val: t('industry_type_15'), id: 'Motion picture, video and television programme production' },
        { val: t('industry_type_16'), id: 'Other professional, scientific and technical activities' },
        { val: t('industry_type_17'), id: 'Postal and courier activities' },
        { val: t('industry_type_18'), id: 'Programming and broadcasting activities' },
        { val: t('industry_type_19'), id: 'Publishing activities' },
        { val: t('industry_type_20'), id: 'Rental and leasing activities' },
        { val: t('industry_type_21'), id: 'Retail trade' },
        { val: t('industry_type_22'), id: 'Scientific research and development' },
        { val: t('industry_type_23'), id: 'Security and investigation activities' },
        { val: t('industry_type_24'), id: 'Services to buildings and landscape activities' },
        { val: t('industry_type_25'), id: 'Sports and recreation activities' },
        { val: t('industry_type_26'), id: 'Travel agency, tour operator' },
        { val: t('industry_type_27'), id: 'Veterinary activities' },
        { val: t('industry_type_28'), id: 'Water transport' }
    ]
)
export const countryList = [
    { "id": 139, "name": "Malta", "iso2": "MT", "iso3": "MLT", "callingCode": "356", "status": 0 },
    { "id": 85, "name": "Germany", "iso2": "DE", "iso3": "DEU", "callingCode": "49", "status": 0 },
    { "id": 1, "name": "Afghanistan", "iso2": "AF", "iso3": "AFG", "callingCode": "93", "status": 1 },
    { "id": 2, "name": "Åland Islands", "iso2": "AX", "iso3": "ALA", "callingCode": "358", "status": 0 },
    { "id": 3, "name": "Albania", "iso2": "AL", "iso3": "ALB", "callingCode": "355", "status": 0 },
    { "id": 4, "name": "Algeria", "iso2": "DZ", "iso3": "DZA", "callingCode": "213", "status": 0 },
    { "id": 5, "name": "American Samoa", "iso2": "AS", "iso3": "ASM", "callingCode": "1684", "status": 1 },
    { "id": 6, "name": "Andorra", "iso2": "AD", "iso3": "AND", "callingCode": "376", "status": 0 },
    { "id": 7, "name": "Angola", "iso2": "AO", "iso3": "AGO", "callingCode": "244", "status": 1 },
    { "id": 8, "name": "Anguilla", "iso2": "AI", "iso3": "AIA", "callingCode": "1264", "status": 0 },
    { "id": 9, "name": "Antarctica", "iso2": "AQ", "iso3": "ATA", "callingCode": "672", "status": 0 },
    { "id": 10, "name": "Antigua and Barbuda", "iso2": "AG", "iso3": "ATG", "callingCode": "1268", "status": 0 },
    { "id": 11, "name": "Argentina", "iso2": "AR", "iso3": "ARG", "callingCode": "54", "status": 0 },
    { "id": 12, "name": "Armenia", "iso2": "AM", "iso3": "ARM", "callingCode": "374", "status": 0 },
    { "id": 13, "name": "Aruba", "iso2": "AW", "iso3": "ABW", "callingCode": "297", "status": 0 },
    { "id": 14, "name": "Australia", "iso2": "AU", "iso3": "AUS", "callingCode": "61", "status": 0 },
    { "id": 15, "name": "Austria", "iso2": "AT", "iso3": "AUT", "callingCode": "43", "status": 0 },
    { "id": 16, "name": "Azerbaijan", "iso2": "AZ", "iso3": "AZE", "callingCode": "994", "status": 0 },
    { "id": 17, "name": "Bahamas", "iso2": "BS", "iso3": "BHS", "callingCode": "1242", "status": 1 },
    { "id": 18, "name": "Bahrain", "iso2": "BH", "iso3": "BHR", "callingCode": "973", "status": 0 },
    { "id": 19, "name": "Bangladesh", "iso2": "BD", "iso3": "BGD", "callingCode": "880", "status": 1 },
    { "id": 20, "name": "Barbados", "iso2": "BB", "iso3": "BRB", "callingCode": "1246", "status": 1 },
    { "id": 21, "name": "Belarus", "iso2": "BY", "iso3": "BLR", "callingCode": "375", "status": 0 },
    { "id": 22, "name": "Belgium", "iso2": "BE", "iso3": "BEL", "callingCode": "32", "status": 0 },
    { "id": 23, "name": "Belize", "iso2": "BZ", "iso3": "BLZ", "callingCode": "501", "status": 0 },
    { "id": 24, "name": "Benin", "iso2": "BJ", "iso3": "BEN", "callingCode": "229", "status": 1 },
    { "id": 25, "name": "Bermuda", "iso2": "BM", "iso3": "BMU", "callingCode": "1441", "status": 0 },
    { "id": 26, "name": "Bhutan", "iso2": "BT", "iso3": "BTN", "callingCode": "975", "status": 0 },
    { "id": 27, "name": "Bolivia (Plurinational State of)", "iso2": "BO", "iso3": "BOL", "callingCode": "591", "status": 0 },
    { "id": 28, "name": "Bonaire, Sint Eustatius and Saba", "iso2": "BQ", "iso3": "BES", "callingCode": "5997", "status": 0 },
    { "id": 29, "name": "Bosnia and Herzegovina", "iso2": "BA", "iso3": "BIH", "callingCode": "387", "status": 0 },
    { "id": 30, "name": "Botswana", "iso2": "BW", "iso3": "BWA", "callingCode": "267", "status": 1 },
    { "id": 31, "name": "Bouvet Island", "iso2": "BV", "iso3": "BVT", "callingCode": "11", "status": 0 },
    { "id": 32, "name": "Brazil", "iso2": "BR", "iso3": "BRA", "callingCode": "55", "status": 0 },
    { "id": 33, "name": "British Indian Ocean Territory", "iso2": "IO", "iso3": "IOT", "callingCode": "246", "status": 0 },
    { "id": 34, "name": "United States Minor Outlying Islands", "iso2": "UM", "iso3": "UMI", "callingCode": "246", "status": 0 },
    { "id": 35, "name": "Virgin Islands (British)", "iso2": "VG", "iso3": "VGB", "callingCode": "1284", "status": 0 },
    { "id": 36, "name": "Virgin Islands (U.S.)", "iso2": "VI", "iso3": "VIR", "callingCode": "1", "status": 1 },
    { "id": 37, "name": "Brunei Darussalam", "iso2": "BN", "iso3": "BRN", "callingCode": "673", "status": 0 },
    { "id": 38, "name": "Bulgaria", "iso2": "BG", "iso3": "BGR", "callingCode": "359", "status": 0 },
    { "id": 39, "name": "Burkina Faso", "iso2": "BF", "iso3": "BFA", "callingCode": "226", "status": 1 },
    { "id": 40, "name": "Burundi", "iso2": "BI", "iso3": "BDI", "callingCode": "257", "status": 0 },
    { "id": 41, "name": "Cambodia", "iso2": "KH", "iso3": "KHM", "callingCode": "855", "status": 1 },
    { "id": 42, "name": "Cameroon", "iso2": "CM", "iso3": "CMR", "callingCode": "237", "status": 0 },
    { "id": 43, "name": "Canada", "iso2": "CA", "iso3": "CAN", "callingCode": "1", "status": 0 },
    { "id": 44, "name": "Cabo Verde", "iso2": "CV", "iso3": "CPV", "callingCode": "238", "status": 1 },
    { "id": 45, "name": "Cayman Islands", "iso2": "KY", "iso3": "CYM", "callingCode": "1345", "status": 0 },
    { "id": 46, "name": "Central African Republic", "iso2": "CF", "iso3": "CAF", "callingCode": "236", "status": 0 },
    { "id": 47, "name": "Chad", "iso2": "TD", "iso3": "TCD", "callingCode": "235", "status": 0 },
    { "id": 48, "name": "Chile", "iso2": "CL", "iso3": "CHL", "callingCode": "56", "status": 0 },
    { "id": 49, "name": "China", "iso2": "CN", "iso3": "CHN", "callingCode": "86", "status": 0 },
    { "id": 50, "name": "Christmas Island", "iso2": "CX", "iso3": "CXR", "callingCode": "61", "status": 0 },
    { "id": 51, "name": "Cocos (Keeling) Islands", "iso2": "CC", "iso3": "CCK", "callingCode": "61", "status": 0 },
    { "id": 52, "name": "Colombia", "iso2": "CO", "iso3": "COL", "callingCode": "57", "status": 0 },
    { "id": 53, "name": "Comoros", "iso2": "KM", "iso3": "COM", "callingCode": "269", "status": 0 },
    { "id": 54, "name": "Congo", "iso2": "CG", "iso3": "COG", "callingCode": "242", "status": 0 },
    { "id": 55, "name": "Congo (Democratic Republic of the)", "iso2": "CD", "iso3": "COD", "callingCode": "243", "status": 0 },
    { "id": 56, "name": "Cook Islands", "iso2": "CK", "iso3": "COK", "callingCode": "682", "status": 0 },
    { "id": 57, "name": "Costa Rica", "iso2": "CR", "iso3": "CRI", "callingCode": "506", "status": 0 },
    { "id": 58, "name": "Croatia", "iso2": "HR", "iso3": "HRV", "callingCode": "385", "status": 0 },
    { "id": 59, "name": "Cuba", "iso2": "CU", "iso3": "CUB", "callingCode": "53", "status": 0 },
    { "id": 60, "name": "Curaçao", "iso2": "CW", "iso3": "CUW", "callingCode": "599", "status": 0 },
    { "id": 61, "name": "Cyprus", "iso2": "CY", "iso3": "CYP", "callingCode": "357", "status": 0 },
    { "id": 62, "name": "Czech Republic", "iso2": "CZ", "iso3": "CZE", "callingCode": "420", "status": 0 },
    { "id": 63, "name": "Denmark", "iso2": "DK", "iso3": "DNK", "callingCode": "45", "status": 0 },
    { "id": 64, "name": "Djibouti", "iso2": "DJ", "iso3": "DJI", "callingCode": "253", "status": 0 },
    { "id": 65, "name": "Dominica", "iso2": "DM", "iso3": "DMA", "callingCode": "1767", "status": 0 },
    { "id": 66, "name": "Dominican Republic", "iso2": "DO", "iso3": "DOM", "callingCode": "1809", "status": 0 },
    { "id": 67, "name": "Ecuador", "iso2": "EC", "iso3": "ECU", "callingCode": "593", "status": 0 },
    { "id": 68, "name": "Egypt", "iso2": "EG", "iso3": "EGY", "callingCode": "20", "status": 0 },
    { "id": 69, "name": "El Salvador", "iso2": "SV", "iso3": "SLV", "callingCode": "503", "status": 0 },
    { "id": 70, "name": "Equatorial Guinea", "iso2": "GQ", "iso3": "GNQ", "callingCode": "240", "status": 0 },
    { "id": 71, "name": "Eritrea", "iso2": "ER", "iso3": "ERI", "callingCode": "291", "status": 0 },
    { "id": 72, "name": "Estonia", "iso2": "EE", "iso3": "EST", "callingCode": "372", "status": 0 },
    { "id": 73, "name": "Ethiopia", "iso2": "ET", "iso3": "ETH", "callingCode": "251", "status": 1 },
    { "id": 74, "name": "Falkland Islands (Malvinas)", "iso2": "FK", "iso3": "FLK", "callingCode": "500", "status": 0 },
    { "id": 75, "name": "Faroe Islands", "iso2": "FO", "iso3": "FRO", "callingCode": "298", "status": 0 },
    { "id": 76, "name": "Fiji", "iso2": "FJ", "iso3": "FJI", "callingCode": "679", "status": 1 },
    { "id": 77, "name": "Finland", "iso2": "FI", "iso3": "FIN", "callingCode": "358", "status": 0 },
    { "id": 78, "name": "France", "iso2": "FR", "iso3": "FRA", "callingCode": "33", "status": 0 },
    { "id": 79, "name": "French Guiana", "iso2": "GF", "iso3": "GUF", "callingCode": "594", "status": 0 },
    { "id": 80, "name": "French Polynesia", "iso2": "PF", "iso3": "PYF", "callingCode": "689", "status": 0 },
    { "id": 81, "name": "French Southern Territories", "iso2": "TF", "iso3": "ATF", "callingCode": "0", "status": 0 },
    { "id": 82, "name": "Gabon", "iso2": "GA", "iso3": "GAB", "callingCode": "241", "status": 0 },
    { "id": 83, "name": "Gambia", "iso2": "GM", "iso3": "GMB", "callingCode": "220", "status": 0 },
    { "id": 84, "name": "Georgia", "iso2": "GE", "iso3": "GEO", "callingCode": "995", "status": 0 },
    { "id": 86, "name": "Ghana", "iso2": "GH", "iso3": "GHA", "callingCode": "233", "status": 1 },
    { "id": 87, "name": "Gibraltar", "iso2": "GI", "iso3": "GIB", "callingCode": "350", "status": 0 },
    { "id": 88, "name": "Greece", "iso2": "GR", "iso3": "GRC", "callingCode": "30", "status": 0 },
    { "id": 89, "name": "Greenland", "iso2": "GL", "iso3": "GRL", "callingCode": "299", "status": 0 },
    { "id": 90, "name": "Grenada", "iso2": "GD", "iso3": "GRD", "callingCode": "1473", "status": 1 },
    { "id": 91, "name": "Guadeloupe", "iso2": "GP", "iso3": "GLP", "callingCode": "590", "status": 0 },
    { "id": 92, "name": "Guam", "iso2": "GU", "iso3": "GUM", "callingCode": "1671", "status": 1 },
    { "id": 93, "name": "Guatemala", "iso2": "GT", "iso3": "GTM", "callingCode": "502", "status": 0 },
    { "id": 94, "name": "Guernsey", "iso2": "GG", "iso3": "GGY", "callingCode": "44", "status": 0 },
    { "id": 95, "name": "Guinea", "iso2": "GN", "iso3": "GIN", "callingCode": "224", "status": 0 },
    { "id": 96, "name": "Guinea-Bissau", "iso2": "GW", "iso3": "GNB", "callingCode": "245", "status": 0 },
    { "id": 97, "name": "Guyana", "iso2": "GY", "iso3": "GUY", "callingCode": "592", "status": 0 },
    { "id": 98, "name": "Haiti", "iso2": "HT", "iso3": "HTI", "callingCode": "509", "status": 1 },
    { "id": 99, "name": "Heard Island and McDonald Islands", "iso2": "HM", "iso3": "HMD", "callingCode": "672", "status": 0 },
    { "id": 100, "name": "Holy See", "iso2": "VA", "iso3": "VAT", "callingCode": "379", "status": 0 },
    { "id": 101, "name": "Honduras", "iso2": "HN", "iso3": "HND", "callingCode": "504", "status": 0 },
    { "id": 102, "name": "Hong Kong", "iso2": "HK", "iso3": "HKG", "callingCode": "852", "status": 0 },
    { "id": 103, "name": "Hungary", "iso2": "HU", "iso3": "HUN", "callingCode": "36", "status": 0 },
    { "id": 104, "name": "Iceland", "iso2": "IS", "iso3": "ISL", "callingCode": "354", "status": 0 },
    { "id": 105, "name": "India", "iso2": "IN", "iso3": "IND", "callingCode": "91", "status": 0 },
    { "id": 106, "name": "Indonesia", "iso2": "ID", "iso3": "IDN", "callingCode": "62", "status": 0 },
    { "id": 107, "name": "Côte d'Ivoire", "iso2": "CI", "iso3": "CIV", "callingCode": "225", "status": 0 },
    { "id": 108, "name": "Iran (Islamic Republic of)", "iso2": "IR", "iso3": "IRN", "callingCode": "98", "status": 1 },
    { "id": 109, "name": "Iraq", "iso2": "IQ", "iso3": "IRQ", "callingCode": "964", "status": 1 },
    { "id": 110, "name": "Ireland", "iso2": "IE", "iso3": "IRL", "callingCode": "353", "status": 0 },
    { "id": 111, "name": "Isle of Man", "iso2": "IM", "iso3": "IMN", "callingCode": "44", "status": 0 },
    { "id": 112, "name": "Israel", "iso2": "IL", "iso3": "ISR", "callingCode": "972", "status": 0 },
    { "id": 113, "name": "Italy", "iso2": "IT", "iso3": "ITA", "callingCode": "39", "status": 0 },
    { "id": 114, "name": "Jamaica", "iso2": "JM", "iso3": "JAM", "callingCode": "1876", "status": 1 },
    { "id": 115, "name": "Japan", "iso2": "JP", "iso3": "JPN", "callingCode": "81", "status": 0 },
    { "id": 116, "name": "Jersey", "iso2": "JE", "iso3": "JEY", "callingCode": "44", "status": 0 },
    { "id": 117, "name": "Jordan", "iso2": "JO", "iso3": "JOR", "callingCode": "962", "status": 0 },
    { "id": 118, "name": "Kazakhstan", "iso2": "KZ", "iso3": "KAZ", "callingCode": "76", "status": 0 },
    { "id": 119, "name": "Kenya", "iso2": "KE", "iso3": "KEN", "callingCode": "254", "status": 1 },
    { "id": 120, "name": "Kiribati", "iso2": "KI", "iso3": "KIR", "callingCode": "686", "status": 0 },
    { "id": 121, "name": "Kuwait", "iso2": "KW", "iso3": "KWT", "callingCode": "965", "status": 0 },
    { "id": 122, "name": "Kyrgyzstan", "iso2": "KG", "iso3": "KGZ", "callingCode": "996", "status": 0 },
    { "id": 123, "name": "Lao People's Democratic Republic", "iso2": "LA", "iso3": "LAO", "callingCode": "856", "status": 1 },
    { "id": 124, "name": "Latvia", "iso2": "LV", "iso3": "LVA", "callingCode": "371", "status": 0 },
    { "id": 125, "name": "Lebanon", "iso2": "LB", "iso3": "LBN", "callingCode": "961", "status": 1 },
    { "id": 126, "name": "Lesotho", "iso2": "LS", "iso3": "LSO", "callingCode": "266", "status": 0 },
    { "id": 127, "name": "Liberia", "iso2": "LR", "iso3": "LBR", "callingCode": "231", "status": 0 },
    { "id": 128, "name": "Libya", "iso2": "LY", "iso3": "LBY", "callingCode": "218", "status": 0 },
    { "id": 129, "name": "Liechtenstein", "iso2": "LI", "iso3": "LIE", "callingCode": "423", "status": 0 },
    { "id": 130, "name": "Lithuania", "iso2": "LT", "iso3": "LTU", "callingCode": "370", "status": 0 },
    { "id": 131, "name": "Luxembourg", "iso2": "LU", "iso3": "LUX", "callingCode": "352", "status": 0 },
    { "id": 132, "name": "Macao", "iso2": "MO", "iso3": "MAC", "callingCode": "853", "status": 0 },
    { "id": 133, "name": "Macedonia (the former Yugoslav Republic of)", "iso2": "MK", "iso3": "MKD", "callingCode": "389", "status": 0 },
    { "id": 134, "name": "Madagascar", "iso2": "MG", "iso3": "MDG", "callingCode": "261", "status": 0 },
    { "id": 135, "name": "Malawi", "iso2": "MW", "iso3": "MWI", "callingCode": "265", "status": 0 },
    { "id": 136, "name": "Malaysia", "iso2": "MY", "iso3": "MYS", "callingCode": "60", "status": 0 },
    { "id": 137, "name": "Maldives", "iso2": "MV", "iso3": "MDV", "callingCode": "960", "status": 0 },
    { "id": 138, "name": "Mali", "iso2": "ML", "iso3": "MLI", "callingCode": "223", "status": 0 },
    { "id": 140, "name": "Marshall Islands", "iso2": "MH", "iso3": "MHL", "callingCode": "692", "status": 0 },
    { "id": 141, "name": "Martinique", "iso2": "MQ", "iso3": "MTQ", "callingCode": "596", "status": 0 },
    { "id": 142, "name": "Mauritania", "iso2": "MR", "iso3": "MRT", "callingCode": "222", "status": 0 },
    { "id": 143, "name": "Mauritius", "iso2": "MU", "iso3": "MUS", "callingCode": "230", "status": 1 },
    { "id": 144, "name": "Mayotte", "iso2": "YT", "iso3": "MYT", "callingCode": "262", "status": 0 },
    { "id": 145, "name": "Mexico", "iso2": "MX", "iso3": "MEX", "callingCode": "52", "status": 0 },
    { "id": 146, "name": "Micronesia (Federated States of)", "iso2": "FM", "iso3": "FSM", "callingCode": "691", "status": 0 },
    { "id": 147, "name": "Moldova (Republic of)", "iso2": "MD", "iso3": "MDA", "callingCode": "373", "status": 0 },
    { "id": 148, "name": "Monaco", "iso2": "MC", "iso3": "MCO", "callingCode": "377", "status": 0 },
    { "id": 149, "name": "Mongolia", "iso2": "MN", "iso3": "MNG", "callingCode": "976", "status": 1 },
    { "id": 150, "name": "Montenegro", "iso2": "ME", "iso3": "MNE", "callingCode": "382", "status": 0 },
    { "id": 151, "name": "Montserrat", "iso2": "MS", "iso3": "MSR", "callingCode": "1664", "status": 0 },
    { "id": 152, "name": "Morocco", "iso2": "MA", "iso3": "MAR", "callingCode": "212", "status": 0 },
    { "id": 153, "name": "Mozambique", "iso2": "MZ", "iso3": "MOZ", "callingCode": "258", "status": 1 },
    { "id": 154, "name": "Myanmar", "iso2": "MM", "iso3": "MMR", "callingCode": "95", "status": 1 },
    { "id": 155, "name": "Namibia", "iso2": "NA", "iso3": "NAM", "callingCode": "264", "status": 0 },
    { "id": 156, "name": "Nauru", "iso2": "NR", "iso3": "NRU", "callingCode": "674", "status": 0 },
    { "id": 157, "name": "Nepal", "iso2": "NP", "iso3": "NPL", "callingCode": "977", "status": 0 },
    { "id": 158, "name": "Netherlands", "iso2": "NL", "iso3": "NLD", "callingCode": "31", "status": 0 },
    { "id": 159, "name": "New Caledonia", "iso2": "NC", "iso3": "NCL", "callingCode": "687", "status": 0 },
    { "id": 160, "name": "New Zealand", "iso2": "NZ", "iso3": "NZL", "callingCode": "64", "status": 0 },
    { "id": 161, "name": "Nicaragua", "iso2": "NI", "iso3": "NIC", "callingCode": "505", "status": 1 },
    { "id": 162, "name": "Niger", "iso2": "NE", "iso3": "NER", "callingCode": "227", "status": 0 },
    { "id": 163, "name": "Nigeria", "iso2": "NG", "iso3": "NGA", "callingCode": "234", "status": 0 },
    { "id": 164, "name": "Niue", "iso2": "NU", "iso3": "NIU", "callingCode": "683", "status": 0 },
    { "id": 165, "name": "Norfolk Island", "iso2": "NF", "iso3": "NFK", "callingCode": "672", "status": 0 },
    { "id": 166, "name": "Korea (Democratic People's Republic of)", "iso2": "KP", "iso3": "PRK", "callingCode": "850", "status": 1 },
    { "id": 167, "name": "Northern Mariana Islands", "iso2": "MP", "iso3": "MNP", "callingCode": "1670", "status": 0 },
    { "id": 168, "name": "Norway", "iso2": "NO", "iso3": "NOR", "callingCode": "47", "status": 0 },
    { "id": 169, "name": "Oman", "iso2": "OM", "iso3": "OMN", "callingCode": "968", "status": 0 },
    { "id": 170, "name": "Pakistan", "iso2": "PK", "iso3": "PAK", "callingCode": "92", "status": 1 },
    { "id": 171, "name": "Palau", "iso2": "PW", "iso3": "PLW", "callingCode": "680", "status": 0 },
    { "id": 172, "name": "Palestine, State of", "iso2": "PS", "iso3": "PSE", "callingCode": "970", "status": 0 },
    { "id": 173, "name": "Panama", "iso2": "PA", "iso3": "PAN", "callingCode": "507", "status": 0 },
    { "id": 174, "name": "Papua New Guinea", "iso2": "PG", "iso3": "PNG", "callingCode": "675", "status": 0 },
    { "id": 175, "name": "Paraguay", "iso2": "PY", "iso3": "PRY", "callingCode": "595", "status": 0 },
    { "id": 176, "name": "Peru", "iso2": "PE", "iso3": "PER", "callingCode": "51", "status": 0 },
    { "id": 177, "name": "Philippines", "iso2": "PH", "iso3": "PHL", "callingCode": "63", "status": 0 },
    { "id": 178, "name": "Pitcairn", "iso2": "PN", "iso3": "PCN", "callingCode": "64", "status": 0 },
    { "id": 179, "name": "Poland", "iso2": "PL", "iso3": "POL", "callingCode": "48", "status": 0 },
    { "id": 180, "name": "Portugal", "iso2": "PT", "iso3": "PRT", "callingCode": "351", "status": 0 },
    { "id": 181, "name": "Puerto Rico", "iso2": "PR", "iso3": "PRI", "callingCode": "1787", "status": 0 },
    { "id": 182, "name": "Qatar", "iso2": "QA", "iso3": "QAT", "callingCode": "974", "status": 0 },
    { "id": 183, "name": "Republic of Kosovo", "iso2": "XK", "iso3": "KOS", "callingCode": "383", "status": 0 },
    { "id": 184, "name": "Réunion", "iso2": "RE", "iso3": "REU", "callingCode": "262", "status": 0 },
    { "id": 185, "name": "Romania", "iso2": "RO", "iso3": "ROU", "callingCode": "40", "status": 0 },
    { "id": 186, "name": "Russian Federation", "iso2": "RU", "iso3": "RUS", "callingCode": "7", "status": 0 },
    { "id": 187, "name": "Rwanda", "iso2": "RW", "iso3": "RWA", "callingCode": "250", "status": 0 },
    { "id": 188, "name": "Saint Barthélemy", "iso2": "BL", "iso3": "BLM", "callingCode": "590", "status": 0 },
    { "id": 189, "name": "Saint Helena, Ascension and Tristan da Cunha", "iso2": "SH", "iso3": "SHN", "callingCode": "290", "status": 0 },
    { "id": 190, "name": "Saint Kitts and Nevis", "iso2": "KN", "iso3": "KNA", "callingCode": "1869", "status": 0 },
    { "id": 191, "name": "Saint Lucia", "iso2": "LC", "iso3": "LCA", "callingCode": "1758", "status": 0 },
    { "id": 192, "name": "Saint Martin (French part)", "iso2": "MF", "iso3": "MAF", "callingCode": "590", "status": 0 },
    { "id": 193, "name": "Saint Pierre and Miquelon", "iso2": "PM", "iso3": "SPM", "callingCode": "508", "status": 0 },
    { "id": 194, "name": "Saint Vincent and the Grenadines", "iso2": "VC", "iso3": "VCT", "callingCode": "1784", "status": 0 },
    { "id": 195, "name": "Samoa", "iso2": "WS", "iso3": "WSM", "callingCode": "685", "status": 1 },
    { "id": 196, "name": "San Marino", "iso2": "SM", "iso3": "SMR", "callingCode": "378", "status": 0 },
    { "id": 197, "name": "Sao Tome and Principe", "iso2": "ST", "iso3": "STP", "callingCode": "239", "status": 0 },
    { "id": 198, "name": "Saudi Arabia", "iso2": "SA", "iso3": "SAU", "callingCode": "966", "status": 0 },
    { "id": 199, "name": "Senegal", "iso2": "SN", "iso3": "SEN", "callingCode": "221", "status": 0 },
    { "id": 200, "name": "Serbia", "iso2": "RS", "iso3": "SRB", "callingCode": "381", "status": 0 },
    { "id": 201, "name": "Seychelles", "iso2": "SC", "iso3": "SYC", "callingCode": "248", "status": 0 },
    { "id": 202, "name": "Sierra Leone", "iso2": "SL", "iso3": "SLE", "callingCode": "232", "status": 0 },
    { "id": 203, "name": "Singapore", "iso2": "SG", "iso3": "SGP", "callingCode": "65", "status": 0 },
    { "id": 204, "name": "Sint Maarten (Dutch part)", "iso2": "SX", "iso3": "SXM", "callingCode": "1721", "status": 0 },
    { "id": 205, "name": "Slovakia", "iso2": "SK", "iso3": "SVK", "callingCode": "421", "status": 0 },
    { "id": 206, "name": "Slovenia", "iso2": "SI", "iso3": "SVN", "callingCode": "386", "status": 0 },
    { "id": 207, "name": "Solomon Islands", "iso2": "SB", "iso3": "SLB", "callingCode": "677", "status": 0 },
    { "id": 208, "name": "Somalia", "iso2": "SO", "iso3": "SOM", "callingCode": "252", "status": 0 },
    { "id": 209, "name": "South Africa", "iso2": "ZA", "iso3": "ZAF", "callingCode": "27", "status": 0 },
    { "id": 210, "name": "South Georgia and the South Sandwich Islands", "iso2": "GS", "iso3": "SGS", "callingCode": "500", "status": 0 },
    { "id": 211, "name": "Korea (Republic of)", "iso2": "KR", "iso3": "KOR", "callingCode": "82", "status": 0 },
    { "id": 212, "name": "South Sudan", "iso2": "SS", "iso3": "SSD", "callingCode": "211", "status": 0 },
    { "id": 213, "name": "Spain", "iso2": "ES", "iso3": "ESP", "callingCode": "34", "status": 0 },
    { "id": 214, "name": "Sri Lanka", "iso2": "LK", "iso3": "LKA", "callingCode": "94", "status": 0 },
    { "id": 215, "name": "Sudan", "iso2": "SD", "iso3": "SDN", "callingCode": "249", "status": 0 },
    { "id": 216, "name": "Suriname", "iso2": "SR", "iso3": "SUR", "callingCode": "597", "status": 0 },
    { "id": 217, "name": "Svalbard and Jan Mayen", "iso2": "SJ", "iso3": "SJM", "callingCode": "4779", "status": 0 },
    { "id": 218, "name": "Swaziland", "iso2": "SZ", "iso3": "SWZ", "callingCode": "268", "status": 0 },
    { "id": 219, "name": "Sweden", "iso2": "SE", "iso3": "SWE", "callingCode": "46", "status": 0 },
    { "id": 220, "name": "Switzerland", "iso2": "CH", "iso3": "CHE", "callingCode": "41", "status": 0 },
    { "id": 221, "name": "Syrian Arab Republic", "iso2": "SY", "iso3": "SYR", "callingCode": "963", "status": 1 },
    { "id": 222, "name": "Taiwan", "iso2": "TW", "iso3": "TWN", "callingCode": "886", "status": 0 },
    { "id": 223, "name": "Tajikistan", "iso2": "TJ", "iso3": "TJK", "callingCode": "992", "status": 0 },
    { "id": 224, "name": "Tanzania, United Republic of", "iso2": "TZ", "iso3": "TZA", "callingCode": "255", "status": 0 },
    { "id": 225, "name": "Thailand", "iso2": "TH", "iso3": "THA", "callingCode": "66", "status": 0 },
    { "id": 226, "name": "Timor-Leste", "iso2": "TL", "iso3": "TLS", "callingCode": "670", "status": 0 },
    { "id": 227, "name": "Togo", "iso2": "TG", "iso3": "TGO", "callingCode": "228", "status": 0 },
    { "id": 228, "name": "Tokelau", "iso2": "TK", "iso3": "TKL", "callingCode": "690", "status": 0 },
    { "id": 229, "name": "Tonga", "iso2": "TO", "iso3": "TON", "callingCode": "676", "status": 0 },
    { "id": 230, "name": "Trinidad and Tobago", "iso2": "TT", "iso3": "TTO", "callingCode": "1868", "status": 1 },
    { "id": 231, "name": "Tunisia", "iso2": "TN", "iso3": "TUN", "callingCode": "216", "status": 0 },
    { "id": 232, "name": "Turkey", "iso2": "TR", "iso3": "TUR", "callingCode": "90", "status": 0 },
    { "id": 233, "name": "Turkmenistan", "iso2": "TM", "iso3": "TKM", "callingCode": "993", "status": 0 },
    { "id": 234, "name": "Turks and Caicos Islands", "iso2": "TC", "iso3": "TCA", "callingCode": "1649", "status": 0 },
    { "id": 235, "name": "Tuvalu", "iso2": "TV", "iso3": "TUV", "callingCode": "688", "status": 0 },
    { "id": 236, "name": "Uganda", "iso2": "UG", "iso3": "UGA", "callingCode": "256", "status": 1 },
    { "id": 237, "name": "Ukraine", "iso2": "UA", "iso3": "UKR", "callingCode": "380", "status": 0 },
    { "id": 238, "name": "United Arab Emirates", "iso2": "AE", "iso3": "ARE", "callingCode": "971", "status": 0 },
    { "id": 239, "name": "United Kingdom of Great Britain and Northern Ireland", "iso2": "GB", "iso3": "GBR", "callingCode": "44", "status": 0 },
    { "id": 240, "name": "United States of America", "iso2": "US", "iso3": "USA", "callingCode": "1", "status": 0 },
    { "id": 241, "name": "Uruguay", "iso2": "UY", "iso3": "URY", "callingCode": "598", "status": 0 },
    { "id": 242, "name": "Uzbekistan", "iso2": "UZ", "iso3": "UZB", "callingCode": "998", "status": 0 },
    { "id": 243, "name": "Vanuatu", "iso2": "VU", "iso3": "VUT", "callingCode": "678", "status": 1 },
    { "id": 244, "name": "Venezuela (Bolivarian Republic of)", "iso2": "VE", "iso3": "VEN", "callingCode": "58", "status": 1 },
    { "id": 245, "name": "Viet Nam", "iso2": "VN", "iso3": "VNM", "callingCode": "84", "status": 0 },
    { "id": 246, "name": "Wallis and Futuna", "iso2": "WF", "iso3": "WLF", "callingCode": "681", "status": 0 },
    { "id": 247, "name": "Western Sahara", "iso2": "EH", "iso3": "ESH", "callingCode": "212", "status": 0 },
    { "id": 248, "name": "Yemen", "iso2": "YE", "iso3": "YEM", "callingCode": "967", "status": 1 },
    { "id": 249, "name": "Zambia", "iso2": "ZM", "iso3": "ZMB", "callingCode": "260", "status": 0 },
    { "id": 250, "name": "Zimbabwe", "iso2": "ZW", "iso3": "ZWE", "callingCode": "263", "status": 1 }
]

const countryISOMapping = {
    AFG: "AF",
    ALA: "AX",
    ALB: "AL",
    DZA: "DZ",
    ASM: "AS",
    AND: "AD",
    AGO: "AO",
    AIA: "AI",
    ATA: "AQ",
    ATG: "AG",
    ARG: "AR",
    ARM: "AM",
    ABW: "AW",
    AUS: "AU",
    AUT: "AT",
    AZE: "AZ",
    BHS: "BS",
    BHR: "BH",
    BGD: "BD",
    BRB: "BB",
    BLR: "BY",
    BEL: "BE",
    BLZ: "BZ",
    BEN: "BJ",
    BMU: "BM",
    BTN: "BT",
    BOL: "BO",
    BES: "BQ",
    BIH: "BA",
    BWA: "BW",
    BVT: "BV",
    BRA: "BR",
    VGB: "VG",
    IOT: "IO",
    BRN: "BN",
    BGR: "BG",
    BFA: "BF",
    BDI: "BI",
    KHM: "KH",
    CMR: "CM",
    CAN: "CA",
    CPV: "CV",
    CYM: "KY",
    CAF: "CF",
    TCD: "TD",
    CHL: "CL",
    CHN: "CN",
    HKG: "HK",
    MAC: "MO",
    CXR: "CX",
    CCK: "CC",
    COL: "CO",
    COM: "KM",
    COG: "CG",
    COD: "CD",
    COK: "CK",
    CRI: "CR",
    CIV: "CI",
    HRV: "HR",
    CUB: "CU",
    CUW: "CW",
    CYP: "CY",
    CZE: "CZ",
    DNK: "DK",
    DJI: "DJ",
    DMA: "DM",
    DOM: "DO",
    ECU: "EC",
    EGY: "EG",
    SLV: "SV",
    GNQ: "GQ",
    ERI: "ER",
    EST: "EE",
    ETH: "ET",
    FLK: "FK",
    FRO: "FO",
    FJI: "FJ",
    FIN: "FI",
    FRA: "FR",
    GUF: "GF",
    PYF: "PF",
    ATF: "TF",
    GAB: "GA",
    GMB: "GM",
    GEO: "GE",
    DEU: "DE",
    GHA: "GH",
    GIB: "GI",
    GRC: "GR",
    GRL: "GL",
    GRD: "GD",
    GLP: "GP",
    GUM: "GU",
    GTM: "GT",
    GGY: "GG",
    GIN: "GN",
    GNB: "GW",
    GUY: "GY",
    HTI: "HT",
    HMD: "HM",
    VAT: "VA",
    HND: "HN",
    HUN: "HU",
    ISL: "IS",
    IND: "IN",
    IDN: "ID",
    IRN: "IR",
    IRQ: "IQ",
    IRL: "IE",
    IMN: "IM",
    ISR: "IL",
    ITA: "IT",
    JAM: "JM",
    JPN: "JP",
    JEY: "JE",
    JOR: "JO",
    KAZ: "KZ",
    KEN: "KE",
    KIR: "KI",
    PRK: "KP",
    KOR: "KR",
    KWT: "KW",
    KGZ: "KG",
    LAO: "LA",
    LVA: "LV",
    LBN: "LB",
    LSO: "LS",
    LBR: "LR",
    LBY: "LY",
    LIE: "LI",
    LTU: "LT",
    LUX: "LU",
    MKD: "MK",
    MDG: "MG",
    MWI: "MW",
    MYS: "MY",
    MDV: "MV",
    MLI: "ML",
    MLT: "MT",
    MHL: "MH",
    MTQ: "MQ",
    MRT: "MR",
    MUS: "MU",
    MYT: "YT",
    MEX: "MX",
    FSM: "FM",
    MDA: "MD",
    MCO: "MC",
    MNG: "MN",
    MNE: "ME",
    MSR: "MS",
    MAR: "MA",
    MOZ: "MZ",
    MMR: "MM",
    NAM: "NA",
    NRU: "NR",
    NPL: "NP",
    NLD: "NL",
    ANT: "AN",
    NCL: "NC",
    NZL: "NZ",
    NIC: "NI",
    NER: "NE",
    NGA: "NG",
    NIU: "NU",
    NFK: "NF",
    MNP: "MP",
    NOR: "NO",
    OMN: "OM",
    PAK: "PK",
    PLW: "PW",
    PSE: "PS",
    PAN: "PA",
    PNG: "PG",
    PRY: "PY",
    PER: "PE",
    PHL: "PH",
    PCN: "PN",
    POL: "PL",
    PRT: "PT",
    PRI: "PR",
    QAT: "QA",
    REU: "RE",
    ROU: "RO",
    RUS: "RU",
    RWA: "RW",
    BLM: "BL",
    SHN: "SH",
    KNA: "KN",
    LCA: "LC",
    MAF: "MF",
    SPM: "PM",
    VCT: "VC",
    WSM: "WS",
    SMR: "SM",
    STP: "ST",
    SAU: "SA",
    SEN: "SN",
    SRB: "RS",
    SYC: "SC",
    SLE: "SL",
    SGP: "SG",
    SXM: "SX",
    SVK: "SK",
    SVN: "SI",
    SLB: "SB",
    SOM: "SO",
    ZAF: "ZA",
    SGS: "GS",
    SSD: "SS",
    ESP: "ES",
    LKA: "LK",
    SDN: "SD",
    SUR: "SR",
    SJM: "SJ",
    SWZ: "SZ",
    SWE: "SE",
    CHE: "CH",
    SYR: "SY",
    TWN: "TW",
    TJK: "TJ",
    TZA: "TZ",
    THA: "TH",
    TLS: "TL",
    TGO: "TG",
    TKL: "TK",
    TON: "TO",
    TTO: "TT",
    TUN: "TN",
    TUR: "TR",
    TKM: "TM",
    TCA: "TC",
    TUV: "TV",
    UGA: "UG",
    UKR: "UA",
    ARE: "AE",
    GBR: "GB",
    USA: "US",
    UMI: "UM",
    URY: "UY",
    UZB: "UZ",
    VUT: "VU",
    VEN: "VE",
    VNM: "VN",
    VIR: "VI",
    WLF: "WF",
    ESH: "EH",
    YEM: "YE",
    ZMB: "ZM",
    ZWE: "ZW",
    XKX: "XK"
  }

  export const getCountryISO2 = (countryCode) =>{
    return countryISOMapping[countryCode]
  }