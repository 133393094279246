import React from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

import Logo from '../../Components/UI/Logo/Logo'


const TopBar = () => {
    const { t, i18n } = useTranslation()
    const changeLanguage = (lng) => {
        Cookies.set("LANG", navigator.language.slice(0, 2), { expires: 7 })
        i18n.changeLanguage(lng)
    }
    return (
        <nav className="navbar navbar-expand-md site-header py-2 hideMobi">
            <div className="container">
                <a className="navbar-brand" href="https://www.paymix.eu" target="_BLANK" rel="noreferrer" aria-label="Paymix SoftPOS by Finance Incorporated Limited">
                    <Logo />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <ul className="navbar-nav mx-auto mb-2 mb-md-0">
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" href="https://www.paymix.eu/faq/" target="_BLANK" rel="noreferrer">{t('top_menu_1')}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" href="https://www.paymix.eu/#contactUs" target="_BLANK" rel="noreferrer">{t('top_menu_2')}</a>
                        </li>
                    </ul>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div className="align-items-end mr-3">
                            <select
                                id="languageSelect"
                                onChange={(e) => changeLanguage(e.target.value)}
                                value={i18n.language}
                                style={{"border":"1px solid #1E053A", "height":"55px", "borderRadius":"10px", "padding":"10px 15px" }}
                            >
                                <option value="en">English</option>
                                <option value="de">Deutsch</option>
                                <option value="tr">Türkçe</option>
                                <option value="it">Italiano</option>
                                <option value="mt">Malti</option>
                                <option value="sr">Srpski</option>
                            </select>
                        </div>
                        <div className="flex-column justify-content-center align-items-center ms-3">
                            <a className="headerBtn" href="https://play.google.com/store/apps/details?id=com.financeincorp.paymixsoftpos" target="_BLANK" rel="noreferrer">
                                {t('top_menu_btn')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default TopBar