import React, { useState } from 'react'

import { countryList } from '../../../Utils/generalUtil'
import "./Select.css"

export const countryData = () => {
    let newOptions = []
    countryList.forEach(
        (el) => {
            if (el.status === 0) {
                const newObj = {
                    value: el.iso2,
                    label: el.name
                }
                newOptions.push(newObj)
            }
        }
    )
    return newOptions
}

export const arrayObjToObj = (data) => {
    let newOptions = []
    data.forEach(
        (el) => {
            const newObj = {
                value: el.id,
                label: el.val
            }
            newOptions.push(newObj)
        }
    )
    return newOptions
}
export const arrayToObj = (data) => {
    let newOptions = []
    data.forEach(
        (el) => {
            const newObj = {
                value: el,
                label: el
            }
            newOptions.push(newObj)
        }
    )
    return newOptions
}
const Select = props => {
    let [selectedItem, setSelectedItem] = useState(props.selectedItem)
    let handleChange = (e) => {
        setSelectedItem(e.target.value)
        props.clicked(e.target.value)
    }
    return (
        <select
            className={["form-select", [props.styles]].join(" ")}
            onChange={handleChange}
            disabled={props.disabled}
            defaultValue={selectedItem}
        >
            {props.options.map((el) => {
                return (<option key={el.value} value={el.value}>{el.label}</option>)
            })}
        </select>
    )
}

export default Select