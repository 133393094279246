import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import ReactGA from "react-ga4";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { b2cPolicies } from '../../Config/authConfig'
import {setGAEvent} from '../../Utils/googleAnalytics'
import Layout from '../../Containers/Layout'
import Button from '../../Components/UI/Button/Button'
import Loader from '../../Components/UI/Loader/Loader'

const Home = () => {
    const { instance } = useMsal()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(true)
    const { t } = useTranslation()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const promoCode = queryParams.get('code');
    if(promoCode !== null){
        Cookies.set('promoCode', promoCode);
    }
    useEffect(() => {
        setGAEvent("page_view","Main Page","Home")
        async function getToken() {
            let request = {
                scopes: [b2cPolicies.scopes],
            }
            await instance.acquireTokenSilent(request).then(tokenResponse => {
                if (tokenResponse.accessToken.length > 0 ) {
                    Cookies.set("token", tokenResponse.accessToken, { expires: 1 })
                    if (Cookies.get("isSignup") === true)
                        navigate("/terms")
                    else
                        navigate("/info")
                } else
                    setLoader(false)
            }).catch(async (err) => {
                setLoader(false)
            })
        }
        getToken()
    })
    const doLogin = async () => {
        setGAEvent("page_view","Main Page - Continue Button Click","Continue Signup")
        try {
            let { idToken } = await instance.loginRedirect()
            Cookies.set("token", idToken, { expires: 1 })
            navigate("/info")
            setLoader(true)
        } catch (err) {
        }
    }
    const handleSignUp = () => {
        setGAEvent("page_view","Main Page - Signup Button Click","Continue Signup")
        navigate("/terms")
    }
    return (
        <Layout>
            <AuthenticatedTemplate>
                <div className="container d-flex flex-column justify-content-center align-items-center fullHeight pt-4">
                    <Loader />
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className="d-flex flex-column justify-content-center align-items-center fullHeight">
                    {loader ? (
                        <Loader />
                    ) : (
                        <div className="homeBox p-4">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div>
                                    <h1>{t('home_title')}</h1><br />
                                    <h3>{t('home_info')}</h3><br /><br />
                                    <h4>{t('home_s1_t')} {t('home_s1_i')}</h4><br />
                                    <h4>{t('home_s2_t')} {t('home_s2_i')}</h4><br />
                                    <h4>{t('home_s3_t')} {t('home_s3_i')}</h4><br />
                                </div>
                                <Button clicked={() => handleSignUp()} btnType={"mt-5 ButtonLogin"} type="submit">{t('btn_signup')}</Button>
                                <Button clicked={() => doLogin()} btnType={"mt-5 ButtonSignup"} type="submit">{t('btn_continue_signup')}</Button>
                            </div>
                        </div>
                    )}
                </div>
            </UnauthenticatedTemplate>
        </Layout>
    )
}
export default Home