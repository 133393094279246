import React from 'react'
import logoImg from '../../../Assets/logo.svg'

const Logo = props => {
    return (
        <img src={logoImg} alt="Paymix SoftPos" style={{'height':props.height}} />
    )
}

export default Logo
