import React, { Component } from 'react'
import Cookies from 'js-cookie'
import DatePicker from 'react-datepicker'
import ReactGA from 'react-ga4'
import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { jwtDecode } from "jwt-decode"

import 'react-datepicker/dist/react-datepicker.css'

import Select, { arrayObjToObj, countryData } from '../../Components/UI/Select/Select'
import { URL_BUSINESS_INFO, CREDIT_BASE_URL, URL_SEND_EMAIL } from '../../Config/urlList'
import { formatDate, getCompanyType, getIndustry } from '../../Utils/generalUtil'
import {setGAEvent} from '../../Utils/googleAnalytics'
import ApiError from '../../Components/UI/Alert/ApiError'
import axios from '../../Utils/axiosCreditsafe'
import personaAxios from '../../Utils/axiosPersona'
import Layout from '../../Containers/Layout'
import Button from '../../Components/UI/Button/Button'
import Modal from '../../Components/UI/Modal/Modal'
import Loader from '../../Components/UI/Loader/Loader'

class CompanyInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showErrModal: false,
            redirect: false,
            gotoHome: false,
            showLoader: true,
            needCard: false,
            token: "",
            showModalErr: "",
            CSTOKEN: "",
            CSID: "",
            companyType: 0,
            companyName: "",
            companyWeb: "",
            companyTradeName: "",
            regIso: Cookies.get("REGION"),
            regDt: new Date(),
            iban: "",
            vatNum: "",
            regNum: "",
            regAddr1: "",
            regAddr2: "",
            regCity: "",
            regCode: "",
            regAddrIso: Cookies.get("REGION"),
            operAddr1: "",
            operAddr2: "",
            operCity: "",
            operCode: "",
            operAddrIso: Cookies.get("REGION"),
            mra1: 20,
            mra2: 50,
            mra3: true,
            showBox: false,
            mra4: 0,
            mra5: Cookies.get("REGION"),
            mra6: "",
            arrayList: [],
            industryList: [],
            CSCOMPANY: [],
            counter: 0,
            screen: 1,
            cs_id: "",
            btnBackDisable: true
        }
    }

    componentDidMount() {
        if (Cookies.get("token") === undefined) {
            this.setState({ redirect: true, gotoHome: true })
        } else {
            setGAEvent("page_view","Business Info","Business Info Screen 1")
            if (this.state.counter === 0) {
                const savedData = Cookies.get("COMPANYINFO")
                const entityId = Cookies.get("ENTITY")
                const { t } = this.props
                const arrayList = getCompanyType(t)
                const industryList = getIndustry(t)
                if (savedData !== undefined) {
                    const jsonObj = JSON.parse(savedData)
                    let tempId = (jsonObj.CSID === undefined) ? 0 : jsonObj.CSID
                    let screen = (jsonObj.screen === undefined) ? 1 : jsonObj.screen
                    this.cookieToState()
                    if (entityId !== undefined) {
                        this.goToScreen()
                    }
                    this.setState({
                        token: Cookies.get("token"),
                        counter: 1,
                        arrayList: arrayList,
                        CSID: tempId,
                        screen: screen,
                        industryList: industryList
                    })
                    if (screen === 1) {
                        if (jsonObj.CSID === undefined || jsonObj.CSID === 0) {
                            this.getComapnies()
                        } else {
                            this.setCompanyId(jsonObj.CSID)
                        }
                    } else {
                        this.setState({ showLoader: false, showModal: false, btnBackDisable: false })
                    }
                } else {
                    this.setState({ showLoader: false, token: Cookies.get("token"), counter: 1, arrayList: arrayList, industryList: industryList })
                }
            }
            if (Cookies.get("promoCode") !== undefined && Cookies.get("promoCode") !== null) {
                this.hasPromoCode();
            }
        }
    }
    hasPromoCode = async () => {

        let cookie = Cookies.get('token')
        const decodedToken = jwtDecode(cookie);
        const customerEmail = decodedToken?.emails[0];

        const promoCode = Cookies.get("promoCode");

        await axios.get(URL_SEND_EMAIL(customerEmail, promoCode)).then(res => {
            Cookies.remove('promoCode', { path: '' })
        }).catch(err => {
            console.error(err)
        })

        Cookies.remove('promoCode', { path: '' })
    }
    goToScreen = () => {
        this.setState({ redirect: true })
    }
    cookieToState = () => {
        const savedData = Cookies.get("COMPANYINFO")
        const jsonObj = JSON.parse(savedData)
        const rdt = (jsonObj.regOn === undefined) ? new Date() : new Date(jsonObj.regOn)
        this.setState({
            CSID: jsonObj.CSID,
            companyType: jsonObj.type,
            companyName: jsonObj.name,
            companyWeb: jsonObj.web,
            companyTradeName: jsonObj.tradeName,
            regIso: (jsonObj.regIn === undefined) ? Cookies.get("REGION") : jsonObj.regIn,
            regDt: rdt,
            vatNum: (jsonObj.vat === undefined) ? "" : jsonObj.vat,
            regNum: (jsonObj.regNo === undefined) ? "" : jsonObj.regNo,
            regAddr1: (jsonObj.regAddr1 === undefined) ? "" : jsonObj.regAddr1,
            regAddr2: (jsonObj.regAddr2 === undefined) ? "" : jsonObj.regAddr2,
            regCity: (jsonObj.regCity === undefined) ? "" : jsonObj.regCity,
            regCode: (jsonObj.regCode === undefined) ? "" : jsonObj.regCode,
            regAddrIso: (jsonObj.regAddrIso === undefined) ? Cookies.get("REGION") : jsonObj.regAddrIso,
            operAddr1: (jsonObj.operAddr1 === undefined) ? "" : jsonObj.operAddr1,
            operAddr2: (jsonObj.operAddr2 === undefined) ? "" : jsonObj.operAddr2,
            operCity: (jsonObj.operCity === undefined) ? "" : jsonObj.operCity,
            operCode: (jsonObj.operCode === undefined) ? "" : jsonObj.operCode,
            operAddrIso: (jsonObj.operAddrIso === undefined) ? Cookies.get("REGION") : jsonObj.operAddrIso,
        })
    }
    sendToServer = () => {
        const savedData = Cookies.get("COMPANYINFO")
        const jsonObj = JSON.parse(savedData)
        const mraInfo = {
            "monthlyTransaction": this.state.mra1,
            "monthlyVolumes": "EUR " + this.state.mra2,
            "serviceDelivered": this.state.mra4 + " days",
            "locationSettlementBank": this.state.mra5,
            "mcc":"Wine producers"
        }
        const regAddr = {
            "addressLine1": jsonObj.regAddr1,
            "addressLine2": jsonObj.regAddr2,
            "city": jsonObj.regCity,
            "postCode": jsonObj.regCode,
            "state": jsonObj.regCity,
            "country": jsonObj.regAddrIso,
        }
        const operAddr = {
            "addressLine1": jsonObj.operAddr1,
            "addressLine2": jsonObj.operAddr2,
            "city": jsonObj.operCity,
            "postCode": jsonObj.operCode,
            "state": jsonObj.operCity,
            "country": jsonObj.operAddrIso,
        }
        const addr = {
            "registeredLocation": regAddr,
            "operatingLocation": operAddr
        }
        const requestBody = {
            "isSoleTrader": (jsonObj.type === "1") ? true : false,
            "registeredCountry": jsonObj.regIn,
            "typeOfIndustry": this.state.mra6,
            "dateOfRegistration": jsonObj.regOn,
            "registryNumber": (jsonObj.regNo === undefined || jsonObj.regNo.length === 0) ? jsonObj.vat : jsonObj.regNo,
            "vatTaxNumber": jsonObj.vat,
            "CompanyName": jsonObj.name,
            "Website": jsonObj.web,
            "TradingAs": jsonObj.tradeName,
            "mraInfo": mraInfo,
            "addressInfo": addr,
            "IsCardRequired": this.state.needCard,
            "IBAN": this.state.iban,
            "creditSafeId": "N/A"
        }
        const config = {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
                'Accept-Language':Cookies.get("LANG")
            }
        }
        console.log("requestBody")
        console.log(requestBody)
        console.log("config")
        console.log(config)
        console.log("URL_BUSINESS_INFO")
        console.log(URL_BUSINESS_INFO)
        personaAxios.post(URL_BUSINESS_INFO, requestBody, config).then(res => {
            Cookies.set("ENTITY", res.data.payload[0].value, { expires: 7 })
            this.setState({ showLoader: false })
            this.goToScreen()
        }).catch(err => {
            console.log("err")
            console.log(err)
            if (err.code === 'ECONNABORTED') {
                this.setState({ showErrModal: true, showModalErr: "We have encountered an error. Kindly inform support with error code: 15 ", showLoader: false })
            } else {
                if (err.response && err.response.data && err.response.data.invalidFields && err.response.data.invalidFields.length > 0) {
                    this.setState({ showErrModal: true, showModalErr: err.response.data.invalidFields[0], showLoader: false })
                } else {
                    this.setState({ showErrModal: true, showModalErr: "An error occured ", showLoader: false })
                }
            }
        })
    }
    goBack = () => {
        let screen = this.state.screen
        if (this.state.screen === 2) {
            this.setState({ screen: 1, btnBackDisable: true })
        } else {
            this.setState({ screen: screen - 1 })
        }
    }
    validateForm = () => {
        const { t } = this.props
        switch (this.state.screen) {
            case 1:
                if (this.state.companyType < 1) {
                    this.setState({ showErrModal: true, showModalErr: t('err_company_type') })
                } else if (this.state.companyName.length === 0) {
                    this.setState({ showErrModal: true, showModalErr: t('err_company_name') })
                } else {
                    const companyInfo = {
                        type: this.state.companyType,
                        name: this.state.companyName,
                        web: this.state.companyWeb,
                        tradeName: this.state.companyTradeName
                    }
                    setGAEvent("page_view","Business Info Screen 1 Next Btn","Business Info Screen 1")
                    setGAEvent("page_view","Business Info Screen 2","Business Info Screen 2")
                    Cookies.set("COMPANYINFO", JSON.stringify(companyInfo), { expires: 7 })
                    this.setState({ showLoader: true })
                    this.getComapnies()
                }
                break
            case 2:
                if (this.state.regIso.length === 0) {
                    this.setState({ showErrModal: true, showModalErr: t('err_reg_iso') })
                } else if (this.state.regDt.length === 0) {
                    this.setState({ showErrModal: true, showModalErr: t('err_reg_date') })
                } else if (this.state.vatNum.length === 0) {
                    this.setState({ showErrModal: true, showModalErr: t('err_vat_tax') })
                } else {
                    const companyInfo = {
                        type: this.state.companyType,
                        name: this.state.companyName,
                        web: this.state.companyWeb,
                        tradeName: this.state.companyTradeName,
                        CSID: this.state.CSID,
                        regIn: this.state.regIso,
                        regOn: this.state.regDt,
                        regNo: this.state.regNum,
                        vat: this.state.vatNum,
                    }
                    setGAEvent("page_view","Business Info Screen 2 Next Btn","Business Info Screen 2")
                    setGAEvent("page_view","Business Info Screen 3","Business Info Screen 3")
                    Cookies.set("COMPANYINFO", JSON.stringify(companyInfo), { expires: 7 })
                    this.setState({ showLoader: false, screen: 3, showModal: false })
                }
                break
            case 3:
                if (this.state.regAddr1.length === 0 && this.state.regCity.length === 0 && this.state.regCode.length === 0) {
                    this.setState({ showErrModal: true, showModalErr: t('err_reg_addr') })
                } else if (this.state.operAddr1.length === 0 && this.state.operCity.length === 0 && this.state.operCode.length === 0) {
                    this.setState({ showErrModal: true, showModalErr: t('err_oper_addr') })
                } else {
                    const companyInfo = {
                        type: this.state.companyType,
                        name: this.state.companyName,
                        web: this.state.companyWeb,
                        tradeName: this.state.companyTradeName,
                        CSID: this.state.CSID,
                        regIn: this.state.regIso,
                        regOn: this.state.regDt,
                        regNo: this.state.regNum,
                        vat: this.state.vatNum,
                        regAddr1: this.state.regAddr1,
                        regAddr2: this.state.regAddr2,
                        regCity: this.state.regCity,
                        regCode: this.state.regCode,
                        regAddrIso: this.state.regAddrIso,
                        operAddr1: this.state.operAddr1,
                        operAddr2: this.state.operAddr2,
                        operCity: this.state.operCity,
                        operCode: this.state.operCode,
                        operAddrIso: this.state.operAddrIso,
                        screen: 4,
                    }
                    setGAEvent("page_view","Business Info Screen 3 Next Btn","Business Info Screen 3")
                    setGAEvent("page_view","Business Info Screen 4","Business Info Screen 4")
                    Cookies.set("COMPANYINFO", JSON.stringify(companyInfo), { expires: 7 })
                    this.setState({ showLoader: false, screen: 4, showModal: false })
                }
                break
            default:
                if (this.state.mra6.length < 2) {
                    this.setState({ showErrModal: true, showModalErr: t('err_mra') })
                } else {
                    this.setState({ showLoader: true })
                    setGAEvent("page_view","Business Info Screen 4  Next Btn","Business Info Screen 4")
                    this.sendToServer()
                }
                break
        }
    }
    getComapnies = () => {
        const bodyData = {
            "username": process.env.REACT_APP_CREDIT_SAFE_UNM,
            "password": process.env.REACT_APP_CREDIT_SAFE_PWD,
        }
        axios.post(CREDIT_BASE_URL + "authenticate", bodyData, null).then(res => {
            const token = res.data.token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            let URL = CREDIT_BASE_URL + "companies?name=" + encodeURI(this.state.companyName)
            if (Cookies.get("REGION") === 'DE')
                URL += "&countries=DE"
            else
                URL += "&countries=MT"
            axios.get(URL, config).then(res => {
                if (res.data.companies.length > 0)
                    this.setState({ CSCOMPANY: res.data.companies, showModal: true, showLoader: false, CSTOKEN: token })
                else
                    this.setState({ screen: 2, btnBackDisable: false, showLoader: false })
            }).catch(err => {
                this.setState({ screen: 2, btnBackDisable: false, showLoader: false })
            })
        }).catch(err => {
            this.setState({ screen: 2, btnBackDisable: false, showLoader: false })
        })
    }
    getAuth = () => {
        const bodyData = {
            "username": process.env.REACT_APP_CREDIT_SAFE_UNM,
            "password": process.env.REACT_APP_CREDIT_SAFE_PWD,
        }
        axios.post(CREDIT_BASE_URL + "authenticate", bodyData, null).then(res => {
            const token = res.data.token
            this.setState({ CSTOKEN: token })
            this.setCompanyId(this.state.CSID)
        }).catch(err => {
        })
    }
    setCompanyId = (id) => {
        this.setState({ screen: 2, btnBackDisable: false, showLoader: false })
        this.setState({ showLoader: true })
        const token = this.state.CSTOKEN
        if (token.length === 0) {
            this.getAuth()
        }
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        let URL = CREDIT_BASE_URL + "companies/" + id
        if (Cookies.get("REGION") === 'DE')
            URL += "?customData=de_reason_code::1"
        axios.get(URL, config).then(res => {
            const company_name = res.data.report.companySummary.businessName
            const reg_in = res.data.report.companySummary.country
            const reg_num = res.data.report.companySummary.companyRegistrationNumber
            const reg_on = new Date(formatDate(res.data.report.companyIdentification.basicInformation.companyRegistrationDate))
            const vat_tax = res.data.report.companyIdentification.basicInformation.vatRegistrationNumber
            const addr1 = res.data.report.contactInformation.mainAddress.street
            const city = res.data.report.contactInformation.mainAddress.city
            const zip = res.data.report.contactInformation.mainAddress.postalCode
            const country = res.data.report.contactInformation.mainAddress.country
            this.setState({
                CSID: id,
                showLoader: false,
                showModal: false,
                companyName: company_name,
                regIso: reg_in,
                regDt: reg_on,
                regNum: reg_num,
                vatNum: vat_tax,
                regAddr1: addr1,
                regCity: city,
                regCode: zip,
                regAddrIso: country,
                operAddr1: addr1,
                operCity: city,
                operCode: zip,
                operAddrIso: country,
                screen: 2,
                btnBackDisable: false
            })
        }).catch(err => {
            this.setState({
                showLoader: false,
                screen: 2,
                btnBackDisable: false
            })
        })
    }
    render() {
        if (this.state.redirect) {
            if (this.state.gotoHome) {
                return (<Navigate to='/' replace={true} />)
            } else if (this.state.companyType === "1") {
                return (<Navigate to='/idverify' replace={true} />)
            } else {
                ReactGA.event({
                    category: "current_step 4" + Cookies.get("LANG"),
                    action: "Next Clicked",
                    label: "4",
                });
                return (<Navigate to='/shareholder' replace={true} />)
            }
        }
        const { t } = this.props
        if (this.state.showLoader) {
            return (
                <Layout>
                    <div className="container d-flex flex-column justify-content-center fullHeight pt-4">
                        <Loader />
                    </div>
                </Layout>
            )
        }
        let screenData = (
            <div className="container d-flex flex-row justify-content-center">
                <div className='d-flex flex-column'>
                    <div className="mt-3">
                        <label className="form-label">{t('lbl_company_type')} <sup>*</sup></label>
                        <Select
                            styles="form-control W550"
                            clicked={(value) => { this.setState({ companyType: value }) }}
                            selectedItem={this.state.companyType}
                            options={arrayObjToObj(this.state.arrayList)}
                        />
                    </div>
                    <div className="mt-3">
                        <label className="form-label">{t('lbl_company_name')} <sup>*</sup></label>
                        <input className="form-control W550" type="text" value={this.state.companyName} onChange={(e) => this.setState({ companyName: e.target.value })} />
                    </div>
                    <div className="mt-3">
                        <label className="form-label">{t('lbl_company_trade_name')}</label>
                        <input className="form-control W550" type="text" value={this.state.companyTradeName} onChange={(e) => this.setState({ companyTradeName: e.target.value })} />
                    </div>
                    <div className="mt-3">
                        <label className="form-label">{t('lbl_company_web')}</label>
                        <input className="form-control W550" type="text" value={this.state.companyWeb} onChange={(e) => this.setState({ companyWeb: e.target.value })} />
                    </div>
                </div>
            </div>
        )
        if (this.state.screen === 2) {
            screenData = (
                <div className="container d-flex flex-row justify-content-center">
                    <div className='d-flex flex-column'>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_reg_in')} <sup>*</sup></label>
                            <Select
                                styles="form-control W550"
                                clicked={(value) => { this.setState({ regIso: value }) }}
                                selectedItem={this.state.regIso}
                                options={countryData()}
                            />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_reg_on')} <sup>*</sup></label><br />
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                onKeyDown={(e) => e.preventDefault()}
                                className='form-control W550 pointer'
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dropdownMode="scroll"
                                disabledKeyboardNavigation="true"
                                selected={this.state.regDt}
                                onChange={(date) => this.setState({ regDt: date })} />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_reg_num')}</label>
                            <input className="form-control W550" type="text" value={this.state.regNum} onChange={(e) => this.setState({ regNum: e.target.value })} />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_vat_tax')}<sup>*</sup></label>
                            <input className="form-control W550" type="text" value={this.state.vatNum} onChange={(e) => this.setState({ vatNum: e.target.value })} />
                        </div>
                    </div>
                </div>
            )
        }
        if (this.state.screen === 3) {
            screenData = (
                <div className="container d-flex flex-row justify-content-between mobiColumn">
                    <div className='d-flex flex-column col-md-6'>
                        <h5>{t('addr1')}</h5>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_addr1')} <sup>*</sup></label>
                            <input className="form-control W550" type="text" value={this.state.regAddr1} onChange={(e) => this.setState({ regAddr1: e.target.value, operAddr1: e.target.value })} />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_addr2')}</label><br />
                            <input className="form-control W550" type="text" value={this.state.regAddr2} onChange={(e) => this.setState({ regAddr2: e.target.value, operAddr2: e.target.value })} />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_city')}</label>
                            <input className="form-control W550" type="text" value={this.state.regCity} onChange={(e) => this.setState({ regCity: e.target.value, operCity: e.target.value })} />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_postcode')}</label>
                            <input className="form-control W550" type="text" value={this.state.regCode} onChange={(e) => this.setState({ regCode: e.target.value, operCode: e.target.value })} />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_country')}<sup>*</sup></label>
                            <Select
                                styles="form-control W550"
                                clicked={(value) => { this.setState({ regAddrIso: value, operAddrIso: value }) }}
                                selectedItem={this.state.regAddrIso}
                                options={countryData()}
                            />
                        </div>
                    </div>
                    <div className='d-flex flex-column col-md-6 mobiColumnMargin'>
                        <h5>{t('addr2')}</h5>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_addr1')} <sup>*</sup></label>
                            <input className="form-control W550" type="text" value={this.state.operAddr1} onChange={(e) => this.setState({ operAddr1: e.target.value })} />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_addr2')}</label><br />
                            <input className="form-control W550" type="text" value={this.state.operAddr2} onChange={(e) => this.setState({ operAddr2: e.target.value })} />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_city')}</label>
                            <input className="form-control W550" type="text" value={this.state.operCity} onChange={(e) => this.setState({ operCity: e.target.value })} />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_postcode')}</label>
                            <input className="form-control W550" type="text" value={this.state.operCode} onChange={(e) => this.setState({ operCode: e.target.value })} />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_country')}<sup>*</sup></label>
                            <Select
                                styles="form-control W550"
                                clicked={(value) => { this.setState({ operAddrIso: value }) }}
                                selectedItem={this.state.operAddrIso}
                                options={countryData()}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        if (this.state.screen === 4) {
            screenData = (
                <div className="container d-flex flex-row justify-content-center">
                    <div className='d-flex flex-column'>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_mra_1')}<sup>*</sup> ({this.state.mra1})</label>
                            <input title={this.state.mra1} type="range" className="form-range W550" min={5} max={800} step={5} value={this.state.mra1} onChange={(e) => this.setState({ mra1: e.target.value })} />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_mra_2')}<sup>*</sup> (&euro;{this.state.mra2})</label>
                            <input title={this.state.mra1} type="range" className="form-range W550" min={50} max={2000} step={50} value={this.state.mra2} onChange={(e) => this.setState({ mra2: e.target.value })} />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_mra_3')}<sup>*</sup></label>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={this.state.mra3} onChange={() => { this.setState({ mra3: !this.state.mra3, showBox: !this.state.showBox }) }} />
                                <label className="form-check-label">{(this.state.mra3) ? t('lbl_yes') : t('lbl_no')}</label>
                            </div>
                        </div>
                        {(this.state.showBox) ? (
                            <div className="mt-3">
                                <label className="form-label">{t('lbl_mra_4')}</label><br />
                                <input className="form-control W550" type="number" value={this.state.mra4} onChange={(e) => this.setState({ mra4: e.target.value })} />
                            </div>
                        ) : null}
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_mra_5')} <sup>*</sup></label>
                            <Select
                                styles="form-control W550"
                                clicked={(value) => { this.setState({ mra5: value }) }}
                                selectedItem={this.state.regIso}
                                options={countryData()}
                            />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_mra_iban')}</label>
                            <input className="form-control W550" type="text" value={this.state.iban} onChange={(e) => this.setState({ iban: e.target.value })} />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t('lbl_mra_6')} <sup>*</sup></label>
                            <Select
                                styles="form-control W550"
                                clicked={(value) => { this.setState({ mra6: value }) }}
                                selectedItem={this.state.companyType}
                                options={arrayObjToObj(this.state.industryList)}
                            />
                        </div>
                        {(Cookies.get("REGION") === 'MT' || Cookies.get("REGION") === 'mt') ? (
                            <div className="mt-3">
                                <label className="form-label">{t('lbl_mra_card')}</label><br />
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={this.state.needCard} onChange={() => { this.setState({ needCard: !this.state.needCard }) }} />
                                    <label className="form-check-label">{(this.state.needCard) ? t('lbl_yes') : t('lbl_no')}</label>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            )
        }
        let modalData = null
        if (this.state.CSCOMPANY.length > 0) {
            modalData = (
                <div className="d-flex flex-row justify-content-center">
                    <div>
                        <table className="table table-bordered table-hover hideMobi" cellPadding={10} cellSpacing={10}>
                            <thead>
                                <tr>
                                    <th>{t('tbl_reg_number')}</th>
                                    <th>{t('tbl_company_name')}</th>
                                </tr>
                            </thead><tbody>
                                {this.state.CSCOMPANY.map((el, index) => (
                                    <tr key={index} className='pointer' onClick={() => this.setCompanyId(el.id)}>
                                        <td>{el.regNo}</td>
                                        <td>{el.name}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan="3">
                                        <center><Button clicked={() => this.setState({ showModal: false, screen: 2, btnBackDisable: false })} btnType={"mt-5 ButtonLogin"}>{t('btn_skip')}</Button></center>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='showMobi d-flex flex-column justify-content-center'>
                            {this.state.CSCOMPANY.map((el, index) => (
                                <div className='pointer' onClick={() => this.setCompanyId(el.id)}>
                                    {el.regNo}&nbsp;-&nbsp;{el.name}
                                </div>
                            ))}
                            <hr />
                            <center>
                                <Button clicked={() => this.setState({ showModal: false, screen: 2, btnBackDisable: false })} btnType={"mt-5 ButtonLogin"}>{t('btn_skip')}</Button>
                            </center>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <>
                <Modal show={this.state.showModal} modalClosed={() => null} modalType="Modal1">
                    <div className="p-3">
                        <h3 className="modalTitle mb-4 text-center">{t('modal1_heading')}</h3>
                        {modalData}
                    </div>
                </Modal>
                <ApiError isSuccess={false} show={this.state.showErrModal} modalClosed={() => this.setState({ showErrModal: false })} modalType="Alert1">
                    <div className="p-3">
                        {this.state.showModalErr}
                    </div>
                </ApiError>
                <Layout>
                    <div className="container d-flex flex-column justify-content-start align-items-center fullHeight pt-4">
                        <h2>{t("step_2_t")}</h2><br />
                        <h4>{t("step_2_i")}</h4><hr />
                        {screenData}
                        <div className="container d-flex flex-row justify-content-center hideMobi mb-5">
                            <div className='d-flex me-5'>
                                <Button clicked={this.goBack} btnType={"mt-5 ButtonLogin W250"} type="submit" disabled={this.state.btnBackDisable}>{t('btn_back')}</Button>
                            </div>
                            <div className='d-flex ms-5'>
                                <Button clicked={this.validateForm} btnType={"mt-5 ButtonLogin W250"} type="submit" disabled={this.state.btnDisable}>{t('btn_next')}</Button>
                            </div>
                        </div>
                        <div className="container d-flex flex-column justify-content-center align-items-center showMobi mb-5">
                            <Button clicked={this.validateForm} btnType={"mt-5 ButtonLogin W250"} type="submit" disabled={this.state.btnDisable}>{t('btn_next')}</Button>
                            <Button clicked={this.goBack} btnType={"mt-5 mobiSpacer ButtonLogin W250"} type="submit" disabled={this.state.btnBackDisable}>{t('btn_back')}</Button>
                        </div>
                    </div>
                </Layout>
            </>
        )
    }
}
export default withTranslation()(CompanyInfo)