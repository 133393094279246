import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'

import { b2cPolicies } from '../../Config/authConfig'

import Layout from '../../Containers/Layout'
import Loader from '../../Components/UI/Loader/Loader'

const Login = () => {
    const { instance } = useMsal()
    const navigate = useNavigate()
    const [TOKEN, setToken] = useState('')
    useEffect(() => {
        var request = {
            scopes: [b2cPolicies.scopes],
        }
        instance.acquireTokenSilent(request).then(tokenResponse => {
            setToken(tokenResponse.accessToken)
            Cookies.set("token", tokenResponse.accessToken, { expires: 1 })
            if (TOKEN.length > 0)
                navigate("/info")
        }).catch(async (err) => {
         })
        doLogin()
    })
    const doLogin = async () => {
        try {
            await instance.handleRedirectPromise()
            await instance.loginRedirect({redirectUri:process.env.REACT_APP_POSTLOGIN_REDIRECT})
        } catch (err) {
        }
    }
    return (
        <Layout>
            <div className="d-flex flex-column justify-content-center align-items-center fullHeight">
                <Loader />
            </div>
        </Layout>
    )
}
export default Login