import React from 'react'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t } = useTranslation()
    return (
        <footer>
            <div className="container text-center text-break">
                <a href="https://www.paymix.eu/about-us/" target="_BLANK" className="pe-1" rel="noreferrer">{t('bottom_menu_1')}</a> |
                <a href="https://www.paymix.eu/imprint/" target="_BLANK" className="pe-1 ps-1" rel="noreferrer">{t('bottom_menu_2')}</a> |
                <a href="https://www.paymix.eu/privacy-cookie-policy/" className="pe-1 ps-1" target="_BLANK" rel="noreferrer">{t('bottom_menu_3')}</a> |
                <a href="https://www.paymix.eu/terms-and-conditions/" className="ps-1" target="_BLANK" rel="noreferrer">{t('bottom_menu_4')}</a><br />
            </div>
        </footer>
    )
}

export default Footer