import { LogLevel, BrowserCacheLocation } from '@azure/msal-browser'

export const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1_softpos_signin',
        forgotPassword: 'B2C_1_ipaymix_forgot',
        register: 'B2C_1A_SPOS_SIGNUP',
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://paymixsso.b2clogin.com/paymixsso.onmicrosoft.com/B2C_1_softpos_signin',
        },
        forgotPassword: {
            authority: 'https://paymixsso.b2clogin.com/paymixsso.onmicrosoft.com/B2C_1_softpos_forgot',
        },
        register: {
            authority: 'https://paymixsso.b2clogin.com/paymixsso.onmicrosoft.com/B2C_1A_SPOS_SIGNUP',
        },
    },
    authorityDomain: 'paymixsso.b2clogin.com',
    scopes: 'https://paymixsso.onmicrosoft.com/c3785c8c-d58c-4cf9-aed9-ff10428e2adc/merchant.signup'
}

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENTID,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_POST_LOGIN_REDIRECT,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation:BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message)
                        return
                    case LogLevel.Info:
                        // console.info(message)
                        return
                    case LogLevel.Verbose:
                        console.debug(message)
                        return
                    case LogLevel.Warning:
                        console.warn(message)
                        return
                    default:
                        return
                }
            },
        },
    },
}
export const loginRequest = {
    scopes: [],
}
export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
}