import React from 'react'

import TopBar from './TopBar/TopBar'
import Footer from './Footer/Footer'

const Layout = (props) => {
    return (
        <div className="maxHeight">
            <TopBar />
            <section className="mainContent">
                {props.children}
            </section>
            <Footer />
        </div>
    )
}

export default Layout