import React, { Component } from 'react'
import Cookies from 'js-cookie'
import moment from 'moment'
import ReactGA from "react-ga4";
import { withTranslation } from 'react-i18next'
import { init } from 'getid-launcher'
import { Navigate } from 'react-router-dom'

import Select, { countryData } from '../../Components/UI/Select/Select'
import { URL_ID_VERIFY } from '../../Config/urlList'
import {setGAEvent} from '../../Utils/googleAnalytics'
import { findObjectByCategory, getCountryISO2 } from '../../Utils/generalUtil'

import axios from '../../Utils/axiosGetId'
import ApiError from '../../Components/UI/Alert/ApiError'
import personaAxios from '../../Utils/axiosPersona'
import Layout from '../../Containers/Layout'
import Button from '../../Components/UI/Button/Button'
import Loader from '../../Components/UI/Loader/Loader'

class IdVerify extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showErrModal: false,
            gotoHome: false,
            showModalErr: "",
            redirect: false,
            showLoader: true,
            btnDisable: false,
            token: "",
            regAddr1: "",
            regAddr2: "",
            regCity: "",
            regCode: "",
            regAddrIso: Cookies.get("REGION"),
            screen: 1,
        }
        this.getData = this.getData.bind(this)
    }
    componentDidMount() {
        if (Cookies.get("token") === undefined) {
            this.setState({ redirect: true, gotoHome: true })
        } else {
            const getIdApplication = window.location.href.substring(
                window.location.href.lastIndexOf("/") + 1
            )
            if (getIdApplication !== "idverify" && getIdApplication.length > 0) {
                setGAEvent("page_view","ID Verify Returned from GetID","ID Verify")
                this.getData(getIdApplication)
                this.setState({ showLoader: true })
            } else {
                setGAEvent("page_view","ID Verify ","ID Verify")
                this.setState({
                    showLoader: false,
                    token: Cookies.get("token"),
                    counter: 1,
                })
                if (this.state.screen === 2) {
                    init(this.getIdConfig)
                }
            }
        }
    }
    getData = (id) => {
        const config = {
            headers: {
                "X-API-Key": process.env.REACT_APP_GETID_KEY,
                "Content-Type": "application/json",
            },
        }
        axios
            .get(id, config)
            .then((res) => {
                this.sendToServer(
                    res.data.servicesResults.docCheck.extracted.ocr,
                    res.data.application.fields
                )
            })
            .catch((err) => {
                this.setState({ redirect: true })
            })
    }
    getIdConfig = {
        flowName: "SoftPosWeb",
        apiUrl: "https://financeincorp.getid.ee",
        sdkKey: "e77fz1xyIUQ6f41fcSpAunJ7tQQLI0b5N5dG4M08XqsZeeaf61shhTJsh6iGjTLf",
        containerId: "getid-component",
        injectCSS: '#getid-main .GiLocaleSelect {display: none !important}',
        styles: {
            '--gi-primary-text-color': '#1E053A',
            '--gi-primary-button-background-color': '#1E053A'
        },
        onComplete({ applicationId }) {
            window.location.href = window.location.href + "/" + applicationId
        },
        onFail({ code, message }) {
            console.log(code, message)
        },
    }
    sendToServer = (resultData, fieldData) => {
        const savedData = Cookies.get("IDINFO")
        const jsonObj = JSON.parse(savedData)
        const address = {
            addressLine1: jsonObj.addr1,
            addressLine2: jsonObj.addr2,
            city: jsonObj.city,
            postCode: jsonObj.code,
            state: jsonObj.city,
            country: jsonObj.iso
        }
        let docType = "ID_CARD"
        let issueDt = ""
        if (findObjectByCategory(resultData, "Document type") === "passport") {
            docType = "PASSPORT"
            issueDt = moment(
                new Date(findObjectByCategory(resultData, "Date of issue"))
            ).format("YYYY-MM-DD")
        } else {
            issueDt =
                findObjectByCategory(fieldData, "Date of issue") === null
                    ? moment(
                        new Date(findObjectByCategory(resultData, "Date of issue"))
                    ).format("YYYY-MM-DD")
                    : moment(
                        new Date(findObjectByCategory(fieldData, "Date of issue"))
                    ).format("YYYY-MM-DD")
        }
        let docNum = (findObjectByCategory(resultData, "Document number") === null) ? findObjectByCategory(resultData, "Personal number") : findObjectByCategory(resultData, "Document number")

        const documentData = {
            documentNumber: docNum,
            issuingCountry: getCountryISO2(
                findObjectByCategory(resultData, "Issue country")
            ),
            documentType: docType,
            documentExpiry: moment(
                new Date(findObjectByCategory(resultData, "Date of expiry"))
            ).format("YYYY-MM-DD"),
            dateOfIssue: issueDt,
        }
        let COB = findObjectByCategory(resultData, "Nationality code")
        if(COB === null){
            COB = findObjectByCategory(resultData, "Nationality")
            if(COB === '??D')
                COB = 'DEU';
        }
        const requestBody = {
            firstName: findObjectByCategory(resultData, "First name"),
            lastName: findObjectByCategory(resultData, "Last name"),
            dateOfBirth: moment(
                new Date(findObjectByCategory(fieldData, "Date of birth"))
            ).format("YYYY-MM-DD"),
            countryOfBirth: getCountryISO2(COB),
            documentData: documentData,
            address: address,
            nfc: false,
        }
        const config = {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
                'Accept-Language':Cookies.get("LANG")
            },
        }
        personaAxios
            .post(URL_ID_VERIFY, requestBody, config)
            .then((res) => {
                this.setState({ redirect: true })
            })
            .catch((err) => {
                
                this.setState({ redirect: true })
            })
    }

    validateForm = () => {
        this.setState({ showLoader: true })
        const { t } = this.props
        if (
            this.state.regAddr1.length === 0 &&
            this.state.regCity.length === 0 &&
            this.state.regCode.length === 0
        ) {
            this.setState({ showErrModal: true, showModalErr: t('err_id_addr'), showLoader: false })
        } else {
            const addrInfo = {
                addr1: this.state.regAddr1,
                addr2: this.state.regAddr2,
                city: this.state.regCity,
                code: this.state.regCode,
                iso: this.state.regAddrIso,
            }
            setGAEvent("page_view","ID Verify next Button","ID Verify")
            Cookies.set("IDINFO", JSON.stringify(addrInfo), { expires: 7 })
            this.setState({ screen: 2, btnDisable: true, showLoader: false })
            init(this.getIdConfig)
        }
    }
    render() {
        if (this.state.redirect) {
            if (this.state.gotoHome) {
                return (<Navigate to='/' replace={true} />)
            } else {
                return (<Navigate to='/thankyou' replace={true} />)
            }
        }
        const { t } = this.props
        if (this.state.showLoader) {
            return (
                <Layout>
                    <div className="container d-flex flex-column justify-content-center fullHeight pt-4">
                        <Loader />
                    </div>
                </Layout>
            )
        }
        let screenData = (
            <>
                <div className="container d-flex flex-row justify-content-center">
                    <div className="d-flex flex-column">
                        <h5>{t("addr3")}</h5>
                        <div className="mt-3">
                            <label className="form-label">
                                {t("lbl_addr1")} <sup>*</sup>
                            </label>
                            <input
                                className="form-control W550"
                                type="text"
                                value={this.state.regAddr1}
                                onChange={(e) => this.setState({ regAddr1: e.target.value })}
                            />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t("lbl_addr2")}</label>
                            <br />
                            <input
                                className="form-control W550"
                                type="text"
                                value={this.state.regAddr2}
                                onChange={(e) => this.setState({ regAddr2: e.target.value })}
                            />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t("lbl_city")}</label>
                            <input
                                className="form-control W550"
                                type="text"
                                value={this.state.regCity}
                                onChange={(e) => this.setState({ regCity: e.target.value })}
                            />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">{t("lbl_postcode")}</label>
                            <input
                                className="form-control W550"
                                type="text"
                                value={this.state.regCode}
                                onChange={(e) => this.setState({ regCode: e.target.value })}
                            />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">
                                {t("lbl_country")}
                                <sup>*</sup>
                            </label>
                            <Select
                                styles="form-control W550"
                                clicked={(value) => {
                                    this.setState({ regAddrIso: value })
                                }}
                                selectedItem={this.state.regAddrIso}
                                options={countryData()}
                            />
                        </div>
                    </div>
                </div>
                <div className="container d-flex flex-row justify-content-center mb-5">
                    <Button
                        clicked={this.validateForm}
                        btnType={"mt-5 ButtonLogin W250"}
                        type="submit"
                        disabled={this.state.btnDisable}
                    >
                        {t('btn_next')}
                    </Button>
                </div>
            </>
        )
        if (this.state.screen === 2) {
            return (
                <>
                    <ApiError isSuccess={false} show={this.state.showErrModal} modalClosed={() => this.setState({ showErrModal: false })} modalType="Alert1">
                        <div className="p-3">
                            {this.state.showModalErr}
                        </div>
                    </ApiError>
                    <Layout>
                        <div id="getid-component" className='mobiFull'></div>
                    </Layout>
                </>
            )
        }
        return (
            <>
                <ApiError isSuccess={false} show={this.state.showErrModal} modalClosed={() => this.setState({ showErrModal: false })} modalType="Alert1">
                    <div className="p-3">
                        {this.state.showModalErr}
                    </div>
                </ApiError>
                <Layout>
                    <div className="container d-flex flex-column justify-content-start align-items-center fullHeight pt-4">
                        <h2>{t("step_3_t")}</h2><br />
                        <h4>{t("step_3_i")}</h4><hr />
                        {screenData}
                    </div>
                </Layout>
            </>
        )
    }
}
export default withTranslation()(IdVerify)
