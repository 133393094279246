import React, { Component } from 'react'

import './Alert.css'

import Backdrop from '../Backdrop/Backdrop'
import Button from '../Button/Button'

import { ReactComponent as Warning } from '../../../Assets/icons/ico_alert.svg';
import { ReactComponent as Success } from '../../../Assets/icons/ico_verified.svg';

class ApiError extends Component {
    render() {
        return (
            <React.Fragment>
                <Backdrop show={this.props.show} clicked={this.props.modalClosed} />
                <div
                    style={{
                        transform: this.props.show ? "translateY(0)" : "translateY(-100vh)",
                        opacity: this.props.show ? "1" : "0",
                        display: this.props.show ? "block" : "hidden"
                    }}
                    className={["Alert ", [this.props.modalType]].join(" ")}
                >  
                    {
                        this.props.isSuccess?(<Success />):(<Warning />)
                    }
                    {this.props.children}
                    <Button clicked={this.props.modalClosed} btnType={"mt-2 mb-2 W100"} type="submit">Close</Button>
                </div>
            </React.Fragment>
        );
    }
}

export default ApiError;