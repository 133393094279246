import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Cookies from 'js-cookie'

import localEn from './Locales/en.json'
import localMt from './Locales/mt.json'
import localSr from './Locales/sr.json'
import localDe from './Locales/de.json'
import localIt from './Locales/it.json'
import localTr from './Locales/tr.json'

const resources = {
    en: {
        translation: localEn,
    },
    de: {
        translation: localDe,
    },
    tr: {
        translation: localTr,
    },
    it: {
        translation: localIt,
    },
    mt: {
        translation: localMt,
    },
    sr: {
        translation: localSr,
    }
}
Cookies.set("LANG", navigator.language.slice(0, 2), { expires: 7 })
i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: navigator.language.slice(0, 2),
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n